import React, { useContext, useMemo } from 'react';

import BuSelect from 'components/UI/BuSelect';
import { DateTypeFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/DateTypeFilter/DateTypeFilter';
import { NumberTypeFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/NumberTypeFilter/NumberTypeFilter';
import { OppStageFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/OppStageFilter/OppStageFilter';
import { PicklistTypeFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/PicklistTypeFilter/PicklistTypeFilter';
import { SingleSelectFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/SingleSelectFilter/SingleSelectFilter';
import { TextTypeFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/TextTypeFilter/TextTypeFilter';
import { UserTypeFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/UserTypeFilter/UserTypeFilter';
import { BOOL_OPTIONS } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/constants';
import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import {
  changeDateValue,
  extractTableNameFromFilter as getLabelWithTableName,
  updateFilters,
} from 'components/dashboard/Metrics/Widget/TemplateFilters/helpers';
import { ForecastPeriodCalendar } from 'components/dashboard/Metrics/common/ForecastPeriodCalendar/ForecastPeriodCalendar';
import { TargetTimePeriodCalendar } from 'components/dashboard/Metrics/common/TargetTimePeriodCalendar/TargetTimePeriodCalendar';
import {
  BOOL_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  NUMBER_COLUMN_TYPES,
  PICKLIST_COLUMN_TYPES,
  SINGLE_SELECT_COLUMN_TYPES,
  TEXT_COLUMN_TYPES,
} from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  DataDescriptor,
  BIMetricsFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isDateFirstPivot?: boolean;
  widgetFilter: DataDescriptor;
  templateFilters: BIMetricsFilter[];
  relativeDateFilterOptions: Filters.DateFilterElement[];
  timeInterval?: string;
  timePeriod?: string;
  targetPeriod?: string;
  disabled?: boolean;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export const TemplateFilter: React.FC<Props> = ({
  isDateFirstPivot,
  widgetFilter,
  templateFilters,
  relativeDateFilterOptions,
  timeInterval,
  timePeriod,
  targetPeriod,
  disabled = false,
  onChangeWidget,
}) => {
  const { hierarchyAlias } = useContext(RevBISettingsContext);

  const templateFilterIndex = useMemo(
    () =>
      templateFilters
        ? templateFilters.findIndex(
            (filter) => filter.column.name === widgetFilter.name
          )
        : 0,
    [JSON.stringify(templateFilters), widgetFilter.name]
  );

  const handleUpdateWidget = (filters: BIMetricsFilter[]): void => {
    onChangeWidget({
      template_filters: filters ?? [],
    });
  };

  const handleDateValueChange = (change: DateOperatorOption): void => {
    const selectedValue = change.selected.map((value) => value.id);
    const operator = change.operator;

    const updatedFilters: BIMetricsFilter[] = changeDateValue(
      selectedValue,
      operator,
      templateFilters,
      templateFilterIndex,
      widgetFilter
    );

    handleUpdateWidget(updatedFilters);
  };

  const handleUpdateFilters = (
    emptyFilters: boolean,
    operator: string,
    value: string | string[] | number
  ): void => {
    const updatedFilters = updateFilters(
      templateFilters,
      emptyFilters,
      templateFilterIndex,
      widgetFilter,
      operator,
      value
    );

    if (updatedFilters) {
      handleUpdateWidget(updatedFilters);
    }
  };

  const handleBooleanValueChange = (value: string): void => {
    handleUpdateFilters(value === 'all', 'eq', value);
  };

  const isTargetPeriodFilter = widgetFilter.name === 'target.target_date';
  const isUserFilter = hierarchyAlias.includes(widgetFilter.name ?? '');
  const isOpportunityStage =
    widgetFilter.name === 'opportunity.opportunity_stage';
  const isForecastPeriodFilter =
    widgetFilter.name === 'forecast_submission.forecast_period';

  const templateFilter = templateFilters[templateFilterIndex];

  /**
   * Becasue in some old dashboard_filters, the label already contains the table name,
   * we check if it does , this is to avoid duplicating the table name in the label
   * and keeping support for old configs
   */
  const labelAlreadyHasTableName = widgetFilter.label.includes(' - ');

  const filterLabel = labelAlreadyHasTableName
    ? widgetFilter.label
    : getLabelWithTableName(widgetFilter);

  return (
    <>
      {DATE_COLUMN_TYPES.has(widgetFilter.type) && (
        <>
          {isTargetPeriodFilter ? (
            <TargetTimePeriodCalendar
              disabled={disabled}
              isDateFirstPivot={isDateFirstPivot}
              selectedValue={
                templateFilter && (templateFilter.value as string | string[])
              }
              targetPeriod={targetPeriod}
              title="Target Time Period"
              onValueChange={handleDateValueChange}
            />
          ) : (
            <>
              {false ? (
                <ForecastPeriodCalendar
                  forecastPeriod={''}
                  disabled={disabled}
                  selectedValue={
                    templateFilter && (templateFilter.value as string[])
                  }
                  title="Forecast Period"
                  onValueChange={handleDateValueChange}
                />
              ) : (
                <DateTypeFilter
                  disabled={disabled}
                  timeInterval={timeInterval}
                  timePeriod={timePeriod}
                  templateFilter={templateFilter}
                  relativeDateFilterOptions={relativeDateFilterOptions}
                  widgetFilterLabel={filterLabel}
                  widgetFilterName={widgetFilter.name}
                  onValueChange={handleDateValueChange}
                />
              )}
            </>
          )}
        </>
      )}

      {PICKLIST_COLUMN_TYPES.has(widgetFilter.type) && (
        <>
          {isUserFilter ? (
            <UserTypeFilter
              disabled={disabled}
              values={(templateFilter?.value as string[]) || []}
              updateFilters={handleUpdateFilters}
            />
          ) : isOpportunityStage ? (
            <OppStageFilter
              disabled={disabled}
              templateFilter={templateFilter}
              widgetFilterLabel={filterLabel}
              widgetFilterName={widgetFilter.name}
              widgetFilterType={widgetFilter.type}
              updateFilters={handleUpdateFilters}
            />
          ) : (
            <PicklistTypeFilter
              disabled={disabled}
              templateFilter={templateFilter}
              widgetFilterLabel={filterLabel}
              widgetFilterName={widgetFilter.name}
              widgetFilterType={widgetFilter.type}
              updateFilters={handleUpdateFilters}
            />
          )}
        </>
      )}

      {SINGLE_SELECT_COLUMN_TYPES.has(widgetFilter.type) && (
        <SingleSelectFilter
          disabled={disabled}
          templateFilter={templateFilter}
          widgetFilterLabel={filterLabel}
          widgetFilterName={widgetFilter.name}
          widgetFilterType={widgetFilter.type}
          updateFilters={handleUpdateFilters}
        />
      )}

      {BOOL_COLUMN_TYPES.has(widgetFilter.type) && (
        <BuSelect
          secondary
          disabled={disabled}
          options={BOOL_OPTIONS}
          onChange={(values: string[]) => handleBooleanValueChange(values[0])}
          defaults={templateFilter ? [templateFilter.value as string] : ['all']}
          placeholder=""
          inlineLabel={filterLabel}
          testingLabel={filterLabel}
        />
      )}

      {NUMBER_COLUMN_TYPES.has(widgetFilter.type) && (
        <NumberTypeFilter
          disabled={disabled}
          templateFilter={templateFilter}
          widgetFilterLabel={filterLabel}
          widgetFilterType={widgetFilter.type}
          updateFilters={handleUpdateFilters}
        />
      )}

      {TEXT_COLUMN_TYPES.has(widgetFilter.type) && (
        <TextTypeFilter
          disabled={disabled}
          templateFilter={templateFilter}
          widgetFilterLabel={filterLabel}
          updateFilters={handleUpdateFilters}
        />
      )}
    </>
  );
};
