import axiosInstance from 'api/axiosInstance';
import {
  CallInsightsResponse,
  CallTodoResponse,
  Step,
} from 'components/dashboard/Calls/CallDetails/types';
import { DealInsights } from 'components/deal/types';

type GetCallInsightsDataMethodParams = {
  callId: string;
};

type GetCallInsightsExternalDataMethodParams = {
  sharedCallId: string;
};

export const getCallInsightsData = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<CallInsightsResponse> => {
  const res = await axiosInstance.get<{ data: CallInsightsResponse }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/${callId}/`
  );

  return res.data.data;
};

export const getCallTodoData = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<CallTodoResponse[]> => {
  const res = await axiosInstance.get<CallTodoResponse[]>(
    `${process.env.REACT_APP_BACKEND_URL}/api/todo?call_id=${callId}`
  );

  return res.data;
};

export const getCallInsightsExternalData = async ({
  sharedCallId,
}: GetCallInsightsExternalDataMethodParams): Promise<CallInsightsResponse> => {
  const res = await axiosInstance.get<{ data: CallInsightsResponse }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/shared/${sharedCallId}/`
  );

  return res.data.data;
};

export const postShareCall = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<string> => {
  const res = await axiosInstance.post<{ share_id: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/${callId}/share/`
  );

  return res.data.share_id;
};

export const putSalesProcess = async ({
  opportunity_id,
  sales_process,
  steps,
}: {
  opportunity_id: string;
  sales_process: string;
  steps: Record<string, Step['new_value']> | null;
}): Promise<DealInsights> => {
  const res = await axiosInstance.put<DealInsights>(
    `${process.env.REACT_APP_BACKEND_URL}/api/sales_process/update_fields`,
    {
      opportunity_id,
      sales_process,
      steps,
    }
  );

  return res.data;
};

export const patchTaskDone = async ({
  task_id,
  isTaskCompleted,
}: {
  task_id: string;
  isTaskCompleted: boolean;
}): Promise<{ status: string }> => {
  const res = await axiosInstance.patch<{ status: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/todo/${task_id}/${
      isTaskCompleted ? 'open' : 'complete'
    }`
  );

  return res.data;
};
