import axiosInstance from './axiosInstance';

import { TableConfigurationData } from 'components/UI/TableConfig/types';
import { getDrilldownUrlQueryParamsObject } from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';
import { DrilldownQueryParams } from 'components/modals/types';
import { TablePrimaryKey } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';

/**
 * This type represents an item of the 'drilldown' for custom objects feature
 * where we show a list of items (like a table) with the data of the custom object
 * each item has a list of properties that we want to show, according to
 * the columns configured that comes from the be
 */

export interface CustomObjectDrilldownItem {
  id: string;
  [key: string]: any;
}

export interface CustomObjectDrilldownDataResponseOnData {
  data: CustomObjectDrilldownItem[];
  count: number;
}

export interface CustomObjectDrilldownDataResponseOnRows {
  rows: CustomObjectDrilldownItem[];
  count: number;
}

/**
 * For some reason the response is different when fetching
 * splits, so considering this we have two different types of responses
 */
type CustomObjectDrilldownDataResponse =
  | CustomObjectDrilldownDataResponseOnData
  | CustomObjectDrilldownDataResponseOnRows;

export interface GetDrilldownColumnsResponse {
  data: TableConfigurationData;
}

export interface GetDrilldownDataForMetricObjectReturn {
  items: CustomObjectDrilldownItem[];
  count: number;
}

export const getIdFromItem = (
  item: CustomObjectDrilldownItem,
  primaryKeyInfo?: TablePrimaryKey
): string => {
  if (primaryKeyInfo) {
    const value = item[primaryKeyInfo.keyProperty];

    if (value !== undefined) {
      return String(value);
    }
  }

  // Fallback to the id property if no primary key configuration\ is provided
  // Id can be in different properties depending on the object
  return item._id ?? item.Id ?? item.id;
};

export type GetDrilldownDataParams = Omit<
  DrilldownQueryParams,
  'point_in_time' | 'target_time_period' | 'time_interval' | 'time_period'
>;

/**
 * For now this is only used for the custom objects and splits, but it could be used for any metric object
 * So we should slowly migrate the usage to this function
 * In case you are migrating it, remember to add the correct types for the response
 * as the structure could be different that the one we are using now (only for custom objects)
 */
export const getDrilldownDataForMetricObject = async (
  metricObject: string,
  params: GetDrilldownDataParams,
  primaryKeyInfo?: TablePrimaryKey
): Promise<GetDrilldownDataForMetricObjectReturn> => {
  const url = `/rev_bi/drill_down/${metricObject}/data`;
  const response = await axiosInstance.post<CustomObjectDrilldownDataResponse>(
    url,
    params,
    {
      params: getDrilldownUrlQueryParamsObject(params),
    }
  );

  const items =
    'data' in response.data ? response.data.data : response.data.rows;

  return {
    items: items.map((item) => ({
      ...item,
      id: getIdFromItem(item, primaryKeyInfo),
    })),
    count: response.data.count,
  };
};

export const getDrilldownColumns = async (apiUrl: string) => {
  const response = await axiosInstance.get<GetDrilldownColumnsResponse>(apiUrl);

  return response.data.data;
};

export type GetDrilldownDataForTablePayload = { [fieldToUpdate: string]: any };

export const updateObjectField = async (
  tableName: string,
  objectId: string,
  updateOperation: GetDrilldownDataForTablePayload
) => {
  const url = `/api/settings/custom_tables/update/${tableName}/${objectId}?origin=revbi`;
  const res = await axiosInstance.post(url, updateOperation);

  return res.data;
};

export interface GetDrilldownTotalParams extends GetDrilldownDataParams {
  columns: string[];
}

export type TotalsData = Array<{ value: number; column: string }>;

export interface GetTotalsResponse {
  total_amount: number;
  totals: TotalsData;
}

export interface GetTotalsResponseData {
  data: GetTotalsResponse;
}

export const getDrilldownTotals = async (
  metricObject: string,
  params: GetDrilldownTotalParams
) => {
  const url = `/rev_bi/drill_down/${metricObject}/totals`;
  const response = await axiosInstance.post<GetTotalsResponseData>(
    url,
    params,
    {
      params: getDrilldownUrlQueryParamsObject(params),
    }
  );

  return response.data.data;
};

export interface IdDeltaFilter extends BIMetricsFilter {
  column: {
    name: string;
    label: string;
    type: 'picklist';
  };
  operator: 'in';
  value: string[];
}

export const getIdsFilter = (
  ids: string[],
  primaryKeyInfo: TablePrimaryKey
): IdDeltaFilter => ({
  column: {
    name: primaryKeyInfo.fullTablePath,
    label: 'Id',
    type: 'picklist',
  },
  operator: 'in',
  value: ids,
});

export const getChangesSinceFilter = (
  changeInterval: string
): BIMetricsFilter => ({
  column: {
    name: 'shared.__changes_since',
    label: 'Shared - Changes Since',
    type: 'select',
  },
  operator: 'in',
  value: [changeInterval],
});

export interface DeltaUpdateMetadata {
  'user.name': string;
  'user.email': string;
  created_at: string;
}

export type DeltaUpdateMetadataDic = Record<string, DeltaUpdateMetadata>;

interface GetDrilldownDeltaDataParams extends GetDrilldownDataParams {
  change_interval: string;
}

export interface CustomObjectDrilldownDeltaItem
  extends CustomObjectDrilldownItem {
  updates_metadata: DeltaUpdateMetadataDic;
}

export interface CustomObjectDrilldownDeltaResponse {
  data: CustomObjectDrilldownDeltaItem[];
  count: number;
}

export interface GetDrilldownDeltaForMetricObjectReturn {
  items: CustomObjectDrilldownDeltaItem[];
  count: number;
}

export const getDrilldownDeltaDataForMetricObject = async (
  metricObject: string,
  params: GetDrilldownDeltaDataParams,
  primaryKeyInfo: TablePrimaryKey
): Promise<GetDrilldownDeltaForMetricObjectReturn> => {
  const url = `/rev_bi/drill_down/${metricObject}/deltas`;
  const response = await axiosInstance.post<CustomObjectDrilldownDeltaResponse>(
    url,
    params,
    {
      params: getDrilldownUrlQueryParamsObject(params),
    }
  );

  return {
    items: response.data.data.map((item) => ({
      ...item,
      id: getIdFromItem(item, primaryKeyInfo),
    })),
    count: response.data.count,
  };
};
