import { getIconByEntityName } from 'common/icons';
import {
  groupLabelWrapper,
  pivotTitleSeparator,
} from 'components/UI/BuSelect/styles';

interface IGroupSeparator {
  entityName?: string;
  group: string;
}

const GroupSeparator = ({ entityName, group }: IGroupSeparator) => {
  const IconPath = entityName ? getIconByEntityName(entityName) : null;

  return (
    <div className={groupLabelWrapper}>
      {IconPath && <img src={IconPath} alt={`${group} icon`} />}
      <div className={pivotTitleSeparator}>{group}</div>
    </div>
  );
};

export default GroupSeparator;
