import React, { useEffect, useState } from 'react';
import { Loader, Tab } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getCallInsightsData } from 'api/CallInsights';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import BuIcon from 'components/UI/BuIcon';
import BuButton from 'components/UI/BuButton';
import TabTitle from 'components/dashboard/Tabs/TabTitle';
import Tooltip from 'components/UI/common/Tooltip';
import * as selectors from 'selectors';
import { goBackOr, goToCalls } from 'actions/routeActions';

import * as styles from './styles';
import HeaderDetails from './HeaderDetails';
import { InsightTab, SummaryTab, ActionItemsTab, TranscriptTab } from './Tabs';
import VideoPlayer from './VideoPlayer';
import CallTimeline from './CallTimeline';
import { VideoProvider } from './VideoPlayer/videoPlayer.context';
import { TranscriptProvider } from './Tabs/Transcript/transcript.context';
import { extendCallsInsightDataObject } from './helpers';
import CallInvite from './HeaderDetails/CallInvite';
import { ActionItemsProvider } from './Tabs/ActionItems/actionItems.context';

type Props = RouteComponentProps<{
  id: string;
}>;

const CallDetails: React.FC<Props> = ({ match }) => {
  const dispatch = useDispatch();
  const { setPartition, clearContext, setRenderExtraTabs } = useHeader();

  const previousRoute = useSelector(selectors.getPreviousRoute);

  useEffect(() => {
    setPartition('calls');

    return clearContext;
  }, []);

  const {
    isFetching: isCallInsightsFetching,
    data: callInsightsData,
    error: callInsightsError,
  } = useQuery({
    queryKey: ['get_call_insights_data', match.params.id],
    retry: false,
    queryFn: async () =>
      extendCallsInsightDataObject(
        await getCallInsightsData({ callId: match.params.id })
      ),
  });

  const handleGoBack = () => dispatch(goBackOr(goToCalls, previousRoute));

  useEffect(() => {
    setRenderExtraTabs(
      <TabTitle
        isActive
        scheme="/transcript/:id"
        params={{ id: match.params.id }}
        title="Call Transcript"
        titleLength={120}
      />
    );
  }, [match.params.id]);

  return (
    <VideoProvider>
      <main className={styles.containerWrapper}>
        <header className={styles.header}>
          <BuButton
            className="back-button"
            borderless
            secondary
            onClick={handleGoBack}
          >
            <BuIcon name={BoostUpIcons.ArrowLeft} />
          </BuButton>

          {!isCallInsightsFetching && !!callInsightsData && (
            <>
              <div className={styles.divider} />
              <div className="header-info-wrapper">
                <Tooltip
                  tooltip={callInsightsData.title}
                  position="bottom left"
                >
                  <div className="call-name">{callInsightsData.title}</div>
                </Tooltip>

                <HeaderDetails
                  attendees={callInsightsData.attendees}
                  participants={callInsightsData.meeting_participants}
                  title={callInsightsData.title}
                  duration={callInsightsData.duration}
                  start={callInsightsData.start}
                  description={callInsightsData.description}
                  speakerPercentages={callInsightsData.speaker_percentages}
                  isCallSummarized={callInsightsData.is_call_summarized}
                  callId={match.params.id}
                  shareId={callInsightsData.share_id}
                />
              </div>
            </>
          )}
        </header>

        {!isCallInsightsFetching && !!callInsightsData && (
          <section
            className={classNames(styles.content, {
              error: callInsightsError,
            })}
          >
            <div>
              <VideoPlayer
                isCallSummarized={callInsightsData.is_call_summarized}
                videoUrl={callInsightsData.media_url}
              />

              <CallTimeline
                participants={callInsightsData.meeting_participants}
                segments={callInsightsData.segments}
                duration={callInsightsData.duration}
                isCallSummarized={callInsightsData.is_call_summarized}
              />
            </div>

            {callInsightsData.is_call_summarized ? (
              <div className={styles.callSummaryInsightActionItemsTranscript}>
                <TranscriptProvider>
                  <ActionItemsProvider callId={match.params.id}>
                    <Tab
                      menu={{ secondary: true, pointing: true }}
                      panes={[
                        {
                          menuItem: 'Summary',
                          render: () => (
                            <SummaryTab
                              accountExecutive={
                                callInsightsData.account_executive
                              }
                              accountName={callInsightsData.account_name}
                              accountId={callInsightsData.account_id}
                              opportunityName={
                                callInsightsData.opportunity_name
                              }
                              opportunityId={callInsightsData.opportunity_id}
                              summary={callInsightsData.call_summary_data}
                            />
                          ),
                        },
                        {
                          menuItem: 'Insights',
                          render: () => (
                            <InsightTab
                              accountExecutive={
                                callInsightsData.account_executive
                              }
                              accountName={callInsightsData.account_name}
                              accountId={callInsightsData.account_id}
                              opportunityName={
                                callInsightsData.opportunity_name
                              }
                              opportunityId={callInsightsData.opportunity_id}
                              positiveMentions={
                                callInsightsData.mentions.positive_mentions
                              }
                              negativeMentions={
                                callInsightsData.mentions.negative_mentions
                              }
                              topics={callInsightsData.topics_discussed}
                              competitors={
                                callInsightsData.competitors_discussed
                              }
                              segments={callInsightsData.segments}
                            />
                          ),
                        },
                        {
                          menuItem: 'Action Items',
                          render: () => (
                            <ActionItemsTab callId={match.params.id} />
                          ),
                        },
                        {
                          menuItem: 'Transcript',
                          render: () => (
                            <TranscriptTab
                              segments={callInsightsData.segments}
                              participants={
                                callInsightsData.meeting_participants
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  </ActionItemsProvider>
                </TranscriptProvider>
              </div>
            ) : (
              <div className={styles.callInviteWrapper}>
                <div className={styles.tabContentWrapper}>
                  <CallInvite
                    attendees={callInsightsData.attendees}
                    description={callInsightsData.description}
                  />
                </div>
              </div>
            )}
          </section>
        )}

        {isCallInsightsFetching && <Loader active />}

        {callInsightsError && (
          <div className={styles.requestErrorWrapper}>
            <h1>ERROR!</h1>

            <span>
              We were unable to retrieve the requested data. Please try again
              later.
            </span>
          </div>
        )}
      </main>
    </VideoProvider>
  );
};

export default CallDetails;
