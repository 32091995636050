import { MAP_CHANGES_SINCE_TO_LABEL } from 'components/dashboard/Metrics/constants';
import {
  tooltipLabel,
  tooltipContainer,
  tooltipCaption,
} from 'components/dashboard/Metrics/Widget/Table/helpers/DeltaTooltip.styles';
import React from 'react';

interface DeltaTooltipProps {
  currentValue: string;
  previousValue: string;
  changesSince: string;
}

const DeltaTooltip: React.FC<DeltaTooltipProps> = ({
  currentValue,
  previousValue,
  changesSince,
}) => {
  return (
    <div className={tooltipContainer}>
      <div>
        <span className={tooltipLabel}>Changed From: </span>
        {previousValue} to {currentValue}
      </div>
      <div className={tooltipCaption}>
        Delta Based on Changes since {MAP_CHANGES_SINCE_TO_LABEL[changesSince]}
      </div>
    </div>
  );
};

export default DeltaTooltip;
