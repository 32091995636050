import { css } from 'emotion';
import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';

import { dateStyle } from 'components/dashboard/Forecast/MeddicSidePanel/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';
import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import {
  DayPickerSingleDateController,
  SingleDatePickerShape,
} from 'react-dates';
import { Popup } from 'semantic-ui-react';

const popupWrapper = css`
  .CalendarMonth {
    td {
      background: #fff !important;
      &.CalendarDay__selected {
        background: var(--bu-primary-500) !important;
      }
      &.CalendarDay {
        border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
      }
    }
  }
`;

type IProps = {
  value: string;
  onChange(value: string | null): void;
  disabled: boolean;
  onClose?(): void;
  onOpen?(): void;
};

const DatePicker: React.FC<IProps> = ({
  value,
  onChange,
  disabled,
  onClose,
  onOpen,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside(onClose);

  const date: moment.Moment | null = useMemo(
    () => (value ? moment(value as string) : null),
    [value]
  );

  const handleDayClick: SingleDatePickerShape['onDateChange'] = (newDate) => {
    onChange(newDate ? moment(newDate).format('YYYY-MM-DD') : null);
    setIsOpen(false);
    onClose && onClose();
  };

  const handleCalendarVisibility = () => {
    if (isOpen) {
      onClose && onClose();
    } else {
      onOpen && onOpen();
    }

    setIsOpen(!isOpen);
  };

  const onFocusChange = ({ focused }: { focused: boolean | null }) => {
    focused && setIsOpen(focused as boolean);
  };

  return (
    <div
      className={classNames([dateStyle, { disabled }])}
      onClick={() => !disabled && handleCalendarVisibility()}
    >
      <Popup
        on="click"
        className={popupWrapper}
        trigger={
          <div className="date-button">
            <div>{date?.format('YYYY-MM-DD')}</div>
            <div>
              <BuIcon
                name={BoostUpIcons.NavMeetings}
                color="var(--bu-primary-500)"
                className={classNames({ disabled })}
              />
            </div>
          </div>
        }
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        disabled={disabled}
        content={
          <DayPickerSingleDateController
            date={date && date.isValid() ? date : null}
            focused={isOpen}
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
            noBorder
            numberOfMonths={1}
            onClose={handleCalendarVisibility}
            onDateChange={handleDayClick}
            onFocusChange={onFocusChange}
          />
        }
      />
    </div>
  );
};

export default DatePicker;
