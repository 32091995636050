import { columnTypes } from 'components/UI/TableConfig/column-helper';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { LinkCellConfig } from 'components/UI/common/TypedTable/renderers/LinkCell';

import { getColumnAlignmentByType } from 'utils/aligns';

export const parseCustomObjectColumns = (
  columns: TableConfigurationColumn[],
  getColumnType: ReturnType<typeof columnTypes>
) => {
  const parsedColumns = (columns || []).map<IColumn>((column, index) => {
    const specificColumnTypeConfig = getColumnType(column, columns);

    const showTooltip = ![
      ColumnTypes.NUMBER,
      ColumnTypes.PERCENT,
      ColumnTypes.MONEY,
    ].includes(specificColumnTypeConfig.type);

    // RC-337: Force money type for number type on RevBI
    const getColumnTypeAlignment = (type: string) =>
      type === 'number' ? 'money' : type;

    const baseColumn = {
      field: column.object_field,
      sort_order: SortOrder.DESCENDING,
      sortable: column.sortable,
      id: `${column.field_name}-${index}`,
      label: column.display_name,
      fieldHeaderHighlight: !!column.fieldHeaderHighlight,
      ...specificColumnTypeConfig,
      showTooltip,
      editable: column.editable,
      align: getColumnAlignmentByType(
        column.type == 'custom'
          ? column.meta?.type
          : getColumnTypeAlignment(column.type)
      ),
    };
    const hasLinkToSalesforce =
      column.object_field.toLocaleLowerCase() === 'name';

    if (hasLinkToSalesforce) {
      baseColumn.type = ColumnTypes.LINK;
      baseColumn.config = {
        link_pattern: '${row.salesforceLink}',
        isNewWindow: true,
      } as LinkCellConfig;
    }

    return baseColumn;
  });

  return parsedColumns;
};
