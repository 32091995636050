import { createContext, useEffect } from 'react';

import { DataDescriptor } from '../metrics.types';
import { useSelector } from 'react-redux';
import { getFeatureFlags, getUser } from 'selectors';
import { useQuery } from '@tanstack/react-query';
import { getRevBISettings as getRevBISettingsApi } from 'api/RevBISettings';

export type DrilldownTableSettings = {
  table_display_name: string;
  url: string;
};

export type DrilldownTableSettingsDictionary = Record<
  string,
  DrilldownTableSettings
>;

export type DefaultDateColumnPerTable = Record<string, DataDescriptor>;

export interface TablePrimaryKey {
  /** The property name that serves as the primary key for the metric (e.g., "Id")
   * Useful when handling specific drilldown data for the metric
   */
  keyProperty: string;

  /** The complete table path including the key (e.g., "Mongo_Territory__c.Id") */
  fullTablePath: string;
}

export type RevBISettings = {
  isLoading: boolean;
  // currency signal for this company, it can possible removed from here because this is duplicated.
  currency: string;
  // is max number of pivot available, in some case it could be less than this max value.
  maxAvailablePivots: number;
  // is the list of fields than can represent the hierarchy for this company.
  hierarchyAlias: string[];
  // value for default toggle on the widget managers actions
  removeReporteesDataFromManagers: boolean;

  /**
   * This is the configuration for the drilldown tables it will be used to know
   * the url to fetch the drilldown data
   *
   * (for now only used on custom objects - every metric should be slowly migrated to use this)
   */
  drilldownTableSettings: DrilldownTableSettingsDictionary;
  /**
   * this is the configuration of the icons
   * if the table appears here means that is an alias of the specific table and should use the icon that represent.
   */
  tableAliasesConfigs: Record<string, string>;
  /**
   * To hide or not the exploratory view when clicking on widget title
   * on published dashboards
   */
  hideWidgetExploratoryView: boolean;
  /**
   * seconds that should be valid the cache before to show the text
   * in red color.
   */
  warningElapsedUpdateTimeSeconds: number;
  /**
   * seconds that should wait before activate de refresh button.
   */
  waitingTimeToRefreshWidgetSeconds: number;
  /**
   * this object contains a table name as a key and its value is the default date column.
   */
  defaultDateColumnPerTable: DefaultDateColumnPerTable;

  /**
   * This is the mapping of the change interval filters values to the valuessed by core.
   */
  changeIntervalMapToCore: Record<string, string>;

  /**
   * Maps table names to their primary key information.
   * Used to know the key that identify unique records in each table.
   */
  primaryKeysPerTable: Record<string, TablePrimaryKey>;
};

export const REV_BI_SETTINGS: RevBISettings = {
  isLoading: true,
  currency: 'USD',
  maxAvailablePivots: 2,
  hierarchyAlias: ['user.name', 'user$name'],
  removeReporteesDataFromManagers: false,
  drilldownTableSettings: {},
  tableAliasesConfigs: {},
  hideWidgetExploratoryView: false,
  warningElapsedUpdateTimeSeconds: 3600,
  waitingTimeToRefreshWidgetSeconds: 3,
  defaultDateColumnPerTable: {},
  changeIntervalMapToCore: {},
  primaryKeysPerTable: {},
};

const RevBISettingsContext = createContext<RevBISettings>(REV_BI_SETTINGS);

const transformPrimaryKeys = (
  rawPrimaryKeys: Record<string, string | null> | undefined
): Record<string, TablePrimaryKey> => {
  const transformedKeys: Record<string, TablePrimaryKey> = {};

  if (!rawPrimaryKeys) {
    return {};
  }

  Object.entries(rawPrimaryKeys).forEach(([table, fullPath]) => {
    // Value in some cases can be null
    // In that case we discard that value
    // In real scenarios it shouldn't be possible
    // To define a drilldown (where this config is used) if the object has no id
    // So it shouldn't be a problem
    if (fullPath) {
      transformedKeys[table] = {
        keyProperty: fullPath.split('.').pop() || '',
        fullTablePath: fullPath,
      };
    }
  });

  return transformedKeys;
};

const RevBISettingsProvider = ({ children }: { children: any }) => {
  const user = useSelector(getUser);
  const thereIsAUserLoggedIn = !!user?.email;

  const { metrics_builder_enabled, revbi_show_dashboard_nav_items_enabled } =
    useSelector(getFeatureFlags);

  const isRevBIEnabled =
    metrics_builder_enabled && revbi_show_dashboard_nav_items_enabled;

  const {
    data: revBISettingsData,
    isFetching: isRevBISettingsFetching,
    refetch,
  } = useQuery({
    queryKey: ['revBISettings', user?.email, user?.login_time],
    queryFn: getRevBISettingsApi,
    enabled: isRevBIEnabled && thereIsAUserLoggedIn,
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: 0,
  });

  useEffect(() => {
    if (user?.login_time) {
      refetch();
    }
  }, [user?.login_time, refetch]);

  let newRevBISettings = { ...REV_BI_SETTINGS };

  if (!isRevBISettingsFetching && revBISettingsData) {
    newRevBISettings = {
      ...REV_BI_SETTINGS,
      ...revBISettingsData,
      isLoading: isRevBISettingsFetching,
    };
  }

  return (
    <RevBISettingsContext.Provider
      value={{
        ...newRevBISettings,
        isLoading: isRevBISettingsFetching,
      }}
    >
      {children}
    </RevBISettingsContext.Provider>
  );
};

export { RevBISettingsContext, RevBISettingsProvider };
