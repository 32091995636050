import {
  HierarchicalWidgetNode,
  WidgetPivotConfiguration,
} from 'api/RevBiWidget';
import { groupBy } from 'ramda';

import {
  getStyleForRowInDepth,
  subtotalRow,
} from 'components/dashboard/Metrics/Widget/Table/styles';
import {
  ExpandableRow,
  PIVOT_HIERARCHY_SEPARATOR,
  PathAwareHierarchicalWidgetNode,
} from 'components/dashboard/Metrics/Widget/hooks/useExpandableWidgetTable/useExpandableWidgetTable.helper';
import { PIVOTS_DELTAS_STATUS_KEY } from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';

const FIXED_KEYS_TO_NOT_REMAP = [
  'pivots',
  'pivot_is_expandible',
  'children',
  'dateValue',
  'nodePath',
];

const appendDateToMetricFieldsInTree = (
  tree: PathAwareHierarchicalWidgetNode[],
  dateField: string,
  otherPivotsFields: string[]
): PathAwareHierarchicalWidgetNode[] =>
  tree.map((node) => {
    const { pivots, pivot_is_expandible, children, ...metrics } = node;
    const newMetrics = Object.keys(metrics).reduce((acc, key) => {
      const isMetric = !otherPivotsFields.includes(key);
      if (isMetric) {
        const newKey = `${key}|${dateField}`;
        acc[newKey] = metrics[key];
      } else {
        // Keep pivot names as they are
        acc[key] = metrics[key];
      }

      return acc;
    }, {} as Record<string, any>);

    return {
      nodePath: node.nodePath,
      pivots:
        pivots &&
        appendDateToMetricFieldsInTree(pivots, dateField, otherPivotsFields),
      pivot_is_expandible: pivot_is_expandible,
      children: children
        ? appendDateToMetricFieldsInTree(children, dateField, otherPivotsFields)
        : children,
      ...newMetrics,
    };
  });

interface HierarchicalWidgetNodeWithDate
  extends PathAwareHierarchicalWidgetNode {
  dateValue: string;
}

/**
 * Merges a list of tree nodes while remapping metric keys to include the date.
 * The function groups nodes by the current pivot field,
 * merges their metrics while appending the date to the metric keys, and recursively applies the same logic
 * to all child nodes.
 *
 * @param tree - Array of nodes, each with a 'dateValue' and possibly child nodes.
 * @param dateFieldValue - The field name in the node object that contains the date value for remapping.
 * @param pivotsFields - An array of field names representing the hierarchy of pivots.
 *                       The first element is considered the current pivot, and the rest are treated recursively.
 *
 * Each node in the merged result will have:
 * - All metrics from the original nodes merged into it, with keys remapped to include the date value.
 * - A 'pivot_is_expandible' property indicating if any original node in the group was expandable.
 * - Child nodes under 'pivots' or 'children' processed in the same way, creating a deep merge if necessary.
 *
 *
 */

const mergeNodesWithDateKeyedMetrics = (
  tree: HierarchicalWidgetNodeWithDate[],
  dateFieldValue: string,
  pivotsFields: string[]
): PathAwareHierarchicalWidgetNode[] => {
  const currentPivotField = pivotsFields[0];
  const nextPivotsFields = pivotsFields.slice(1);

  // If this arrays keep growing, consider sending metrics array to the function
  // so instead of checking for each key to not map, we just check if the key is in the array
  const keysToNotRemap = [...FIXED_KEYS_TO_NOT_REMAP, ...pivotsFields];

  const groupedByPivotFields = groupBy<HierarchicalWidgetNodeWithDate>(
    (node) => node[currentPivotField]
  )(tree);

  const mergedNodes = Object.values(groupedByPivotFields).map((nodes) => {
    const someIsExpandible = nodes.some((node) => node.pivot_is_expandible);

    // Append date to metrics and merge child nodes
    // so we know where the node comes from
    const allPivots = nodes.flatMap((node) =>
      node.pivots
        ? node.pivots.map((childNode) => ({
            ...childNode,
            dateValue: node.dateValue,
          }))
        : []
    );

    const allChildren = nodes.flatMap((node) =>
      node.children
        ? node.children.map((childNode) => ({
            ...childNode,
            dateValue: node.dateValue,
          }))
        : []
    );

    const allMetrics = nodes.reduce((acc, node) => {
      for (const key in node) {
        // Only merge remap metrics
        if (!keysToNotRemap.includes(key)) {
          const remappedKey = `${key}|${node.dateValue}`;
          acc[remappedKey] = node[key];
        }
      }

      return acc;
    }, {} as Record<string, any>);
    const firstNode = nodes[0];

    return {
      ...firstNode,
      ...allMetrics,
      // We need to update the nodePath to remove the first pivot
      // That was the date
      nodePath: firstNode.nodePath
        .split(PIVOT_HIERARCHY_SEPARATOR)
        .slice(1)
        .join(PIVOT_HIERARCHY_SEPARATOR),
      pivot_is_expandible: someIsExpandible,
      pivots: mergeNodesWithDateKeyedMetrics(
        allPivots,
        dateFieldValue,
        nextPivotsFields
      ),
      children: mergeNodesWithDateKeyedMetrics(
        allChildren,
        dateFieldValue,
        pivotsFields
      ),
    };
  });

  return mergedNodes;
};

const getSubtotalRow = (
  tree: PathAwareHierarchicalWidgetNode[],
  dateField: string
): PathAwareHierarchicalWidgetNode =>
  tree.reduce(
    (acc, node) => {
      const dateValue = node[dateField];
      for (const key in node) {
        if (!FIXED_KEYS_TO_NOT_REMAP.includes(key)) {
          const remappedKey = `${key}|${dateValue}`;
          acc[remappedKey] = node[key];
        }
      }

      return acc;
    },
    {
      id: 'Subtotal',
      nodePath: 'Subtotal',
      isSubtotal: true,
    } as PathAwareHierarchicalWidgetNode
  );

export const remapDatePivotedTree = (
  tree: PathAwareHierarchicalWidgetNode[],
  pivots: WidgetPivotConfiguration[]
): PathAwareHierarchicalWidgetNode[] => {
  const dateField = pivots[0].field_name;
  const otherPivotsFields = pivots.slice(1).map((pivot) => pivot.field_name);

  const allPivots = tree.flatMap((node) =>
    node.pivots
      ? node.pivots.map((childNode) => ({
          ...childNode,
          dateValue: node[dateField],
        }))
      : []
  );

  const treeWithDateKeyedMetrics = mergeNodesWithDateKeyedMetrics(
    allPivots,
    dateField,
    otherPivotsFields
  );

  const subtotalNode = getSubtotalRow(tree, dateField);

  return [...treeWithDateKeyedMetrics, subtotalNode];
};

export const remapDatePivotDelta = (
  deltasByPath: Record<string, HierarchicalWidgetNode>
) => {
  const remappedDeltas: Record<string, HierarchicalWidgetNode> = {};

  Object.entries(deltasByPath).forEach(([originalPath, deltaNode]) => {
    const isStatusDelta = originalPath.endsWith(PIVOTS_DELTAS_STATUS_KEY);
    const isLoading = isStatusDelta && deltaNode.isLoading;
    // If delta is loading we still don't have any date, so we can't remap
    // We need to keep the original path
    if (isLoading) {
      remappedDeltas[originalPath] = {
        isLoading: true,
      };
      return;
    }

    const [dateValue, ...remainingPathParts] = originalPath.split(
      PIVOT_HIERARCHY_SEPARATOR
    );
    const isSubtotalDelta = remainingPathParts.length === 0;

    const remappedPath = isSubtotalDelta
      ? 'Subtotal'
      : remainingPathParts.join(PIVOT_HIERARCHY_SEPARATOR);

    const remappedDeltaNode = remappedDeltas[remappedPath] || {};

    for (const metricKey in deltaNode) {
      if (!FIXED_KEYS_TO_NOT_REMAP.includes(metricKey)) {
        const remappedMetricKey = `${metricKey}|${dateValue}`;
        remappedDeltaNode[remappedMetricKey] = deltaNode[metricKey];
      }
    }

    remappedDeltas[remappedPath] = remappedDeltaNode;
  });

  return remappedDeltas;
};

export const getRowClassName = (row: ExpandableRow) => {
  if (row.isSubtotal) {
    return subtotalRow;
  }
  const hierarchyDepth = row.nodePath.split(PIVOT_HIERARCHY_SEPARATOR).length;
  return getStyleForRowInDepth(hierarchyDepth);
};
