import styled from '@emotion/styled';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';

const CheckBoxContainer = styled.div`
  line-height: var(--bu-control-height-regular);
  display: flex;
  cursor: pointer;

  &.has-label > .bu-checkbox-icon {
    margin-right: 0.5em;
  }

  &.bu-small {
    height: var(--bu-control-height-small);
    line-height: var(--bu-control-height-small);
    font-size: var(--bu-font-size-small);
  }

  &.bu-big {
    height: var(--bu-control-height-big);
    line-height: var(--bu-control-height-big);
    font-size: var(--bu-font-size-big);
  }

  & > input {
    display: none;
  }

  .bu-checkbox-icon {
    color: var(--bu-gray-500);
    font-size: 1em;

    &:hover:not(.disabled, .readOnly) {
      color: var(--bu-primary-500);
    }
  }

  .bu-icon-checkbox_checked_full,
  .bu-icon-checkbox_indeterminate_full {
    color: var(--bu-primary-500);

    &:hover:not(.disabled, .readOnly) {
      color: var(--bu-primary-700);
    }

    &.readOnly {
      color: var(--bu-gray-500);
    }
  }

  &.bu-secondary .bu-checkbox-icon {
    color: var(--bu-gray-700);
  }

  &.bu-disabled,
  &.bu-read-only {
    opacity: var(--bu-control-disable-opacity);
    cursor: default;
    pointer-events: none;
  }

  .bu-checkbox-label {
    user-select: none;
  }
`;

export type ICheckboxProps = {
  secondary?: boolean;
  size?: BuControlSize;
  label?: string;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (checked: boolean) => void;
  elementLabel?: ReactNode;
} & Common.DataTestingType;

const COMPONENT_TESTING_NAME = 'Checkbox';
const BuCheckbox: React.FC<ICheckboxProps> = ({
  secondary,
  size = BuControlSize.REGULAR,
  checked = false,
  indeterminate = false,
  onChange = () => {},
  label,
  disabled,
  readOnly,
  elementLabel,
  testingLabel = '',
}) => {
  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;
  return (
    <CheckBoxContainer
      className={classNames(size, {
        'bu-secondary': secondary,
        'bu-disabled': disabled,
        'bu-read-only': readOnly,
        'has-label': label || elementLabel,
      })}
      onClick={(e) => {
        e.stopPropagation();

        if (!disabled && !readOnly) {
          onChange(!checked);
        }
      }}
    >
      <input
        data-testing={`${componentDataTestingKey}-${label?.replaceAll(
          ' ',
          '_'
        )}`}
        readOnly
        type="checkbox"
        checked={checked}
        disabled={disabled}
      />

      <BuIcon
        name={
          indeterminate
            ? secondary
              ? BoostUpIcons.CheckboxIndeterminate
              : BoostUpIcons.CheckboxIndeterminateFull
            : checked
            ? secondary
              ? BoostUpIcons.CheckboxChecked
              : BoostUpIcons.CheckboxCheckedFull
            : BoostUpIcons.CheckboxEmpty
        }
        className={classNames('bu-checkbox-icon', { disabled, readOnly })}
      />

      <label className="bu-checkbox-label">
        {label}
        {elementLabel}
      </label>
    </CheckBoxContainer>
  );
};

export default BuCheckbox;
