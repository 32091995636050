import { GetDealsParams } from 'api/Deals';
import { GetDrilldownDataForTablePayload } from 'api/RevBiDrilldown';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import { CustomMutation } from 'components/hooks/useManageDrilldownData';
import { updateDeal } from 'api/Deals';
import { DrillDownParams } from 'components/dashboard/Metrics/metrics.types';
export type UseManageRevBiDealsModalParams = Omit<
  GetDealsParams,
  'page_number' | 'sort'
>;

export const updateDealMutation: CustomMutation = async (
  _metricObject: string,
  id: string,
  editedFields: GetDrilldownDataForTablePayload
): Promise<void> => {
  await updateDeal({ id, editedFields, origin: 'revbi' });
};
