import classNames from 'classnames';
import { css } from 'emotion';
import { isNil } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { formatAmount } from 'common/helpers';
import { shortNumberWithConfig } from 'common/numbers';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import * as selectors from 'selectors';
import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuSkeleton from 'components/UI/BuSkeleton';
import { ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import { isValidDelta } from './Badge.helper';

const fontColor: Record<string, string> = {
  green: 'var(--bu-white)',
  grey: 'var(--bu-gray-600)',
  red: 'var(--bu-white)',
};

const bgColor: Record<string, string> = {
  green: 'var(--bu-green-500)',
  grey: 'var(--bu-table-border-color)',
  red: 'var(--bu-red-400)',
};

const badgeValueColor: Record<string, string> = {
  green: 'var(--bu-green-500)',
  grey: 'var(--bu-gray-600)',
  red: 'var(--bu-red-400)',
};

const badge = (color: string, onClick?: () => void) => css`
  height: 16px;
  width: fit-content;
  margin-top: 5px;
  border-radius: 7px;
  font-family: var(--bu-font-medium);
  padding: 1.5px 7px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  width: max-content;
  cursor: ${onClick ? 'pointer' : 'default'};
  background-color: ${bgColor[color]} !important;
  color: ${fontColor[color]} !important;
  margin-left: auto;
`;

const badgeWrapper = (onClick?: () => void, valueType?: string) => css`
  height: 16px;
  width: fit-content;
  font-family: var(--bu-font-medium);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-bottom: 1px;
  padding-top: 1px;
  cursor: ${onClick ? 'pointer' : 'default'};

  ${valueType === 'number' ||
  valueType === 'money' ||
  valueType === 'corporate_currency'
    ? `
    margin-top: 5px;
    margin-left: auto;
  `
    : `
    margin: 0 auto;
    margin-top: 5px;
  `};
`;

const arrowWrapper = css`
  align-self: center;
  text-align: center;
  line-height: 1;
  font-size: 6px;
  padding: 5px;
  border-radius: 4px;
  margin-right: 4px;

  &.up {
    background-color: var(--bu-green-500);
    color: var(--bu-white);
  }

  &.down {
    background-color: var(--bu-red-400);
    color: var(--bu-white);
  }
`;

const badgeValue = (color: string) => css`
  color: ${badgeValueColor[color]} !important;
`;

const DECIMALS_CONFIG = {
  D: 0,
  K: 0,
  M: 1,
  B: 1,
  T: 1,
};

const getFormattedAmount = (
  value: number,
  valueType: ColumnTypes,
  companyCurrencyCode: string,
  positiveNegativeSign: boolean = true
) => {
  switch (valueType) {
    case ColumnTypes.TEXT:
      return shortNumberWithConfig(
        DECIMALS_CONFIG,
        positiveNegativeSign
      )(value);
    default:
      return formatAmount(
        companyCurrencyCode,
        value,
        positiveNegativeSign,
        positiveNegativeSign
      );
  }
};

interface BadgeProps {
  value?: number | string;
  valueType?: ColumnTypes;
  onClick?: () => void;
  showArrow?: boolean;
  isLoading?: boolean;
  configFormatter?: (
    value: ValueProp,
    valueType: ColumnTypes
  ) => React.ReactNode;
}

const Badge = ({
  value,
  valueType = ColumnTypes.MONEY,
  onClick,
  showArrow = true,
  isLoading = false,
  configFormatter,
}: BadgeProps) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  let color = 'grey';

  if (isLoading) {
    return <BuSkeleton width={60} height={14} borderRadius={4} />;
  }

  if (!isValidDelta(value)) {
    return <span>-</span>;
  }

  if (Number(value) > 0) {
    color = 'green';
  } else if (Number(value) < 0) {
    color = 'red';
  }

  /**
   * if the main value has a formatter, use it, otherwise use the default formatter
   */
  const formattedAmount = configFormatter
    ? configFormatter(value as number, valueType)
    : getFormattedAmount(
        value as number,
        valueType,
        companyCurrencyCode,
        !showArrow
      );

  return (
    <div
      onClick={onClick}
      className={
        showArrow
          ? badgeWrapper(onClick, valueType)
          : classNames('amount-badge', badge(color, onClick))
      }
    >
      {showArrow ? (
        <>
          {Number(value) !== 0 && (
            <div
              className={classNames(
                arrowWrapper,
                color === 'green' ? 'up' : 'down'
              )}
            >
              {color === 'green' ? (
                <BuIcon name={BoostUpIcons.ChangeUp} />
              ) : (
                <BuIcon name={BoostUpIcons.ChangeDown} />
              )}
            </div>
          )}

          <span className={badgeValue(color)}>{formattedAmount}</span>
        </>
      ) : (
        <>{formattedAmount}</>
      )}
    </div>
  );
};

export default Badge;
