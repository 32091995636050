import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, ReactNode, useEffect } from 'react';

import { getCallTodoData } from 'api/CallInsights';

import { CallTodoResponse } from '../../types';

interface ActionItemsContextType {
  callTodoData: CallTodoResponse[];
  isFetchingCallTodoData: boolean;
}

const ActionItemsContext = createContext<ActionItemsContextType | undefined>(
  undefined
);

export const ActionItemsProvider: React.FC<{
  children: ReactNode;
  callId: string;
}> = ({ children, callId }) => {
  const { data: callTodoData, isFetching: isFetchingCallTodoData } = useQuery(
    ['callTodoData', callId],
    () => getCallTodoData({ callId })
  );

  return (
    <ActionItemsContext.Provider
      value={{
        callTodoData: callTodoData || [],
        isFetchingCallTodoData,
      }}
    >
      {children}
    </ActionItemsContext.Provider>
  );
};

export const useActionItemsContext = (): ActionItemsContextType => {
  const context = useContext(ActionItemsContext);
  if (!context) {
    throw new Error(
      'useActionItemsContext must be used within a ActionItemsProvider'
    );
  }
  return context;
};
