import React, { useCallback, useEffect } from 'react';
import DOMPurify from 'dompurify';

import { useTranscriptContext } from './transcript.context';
import { Segment } from '../../types';
import RoundedAvatarIcon from '../../RoundedAvatarIcon';

type Props = {
  segment: Segment;
  color: string;
  index: number;
  style: React.CSSProperties;
  segmentRefs: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
  handleClickSegmentCard: (startTime: number) => void;
  onLoad(): void;
};

const SegmentCard: React.FC<Props> = ({
  segment,
  color,
  index,
  style,
  segmentRefs,
  handleClickSegmentCard,
  onLoad,
}) => {
  const { searchText, searchedSegments } = useTranscriptContext();

  useEffect(() => onLoad(), []);

  const sanitizeAndHighlightTranscript = useCallback(
    (transcript: string) => {
      if (!searchedSegments.length) {
        return transcript;
      }

      const sanitizedTranscript = DOMPurify.sanitize(transcript);

      return sanitizedTranscript.replace(
        new RegExp(`(${searchText})`, 'gi'),
        (match) => `<mark>${match}</mark>`
      );
    },
    [searchedSegments]
  );

  return (
    <div style={style}>
      <div
        className="segment-card"
        key={segment._id}
        onClick={() => handleClickSegmentCard(index)}
        ref={(el) => (segmentRefs.current[index] = el)}
      >
        <div className="active-border" style={{ borderColor: color }} />

        <RoundedAvatarIcon color={color} />

        <div className="message-info">
          <div className="message-info-name-time">
            <span>{segment.speaker_name}</span>
            <span>{segment.formattedStartTime}</span>
          </div>

          <p
            dangerouslySetInnerHTML={{
              __html: sanitizeAndHighlightTranscript(segment.transcript),
            }}
          />
        </div>
      </div>

      <div className="divider" />
    </div>
  );
};

export default SegmentCard;
