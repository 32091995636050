import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import axiosInstance from './axiosInstance';

export interface GetSettingsForTableConfig {
  collectionName: string;
  tableName: string;
}

export type GetSettingsForTableParams = GetSettingsForTableConfig | string;

export interface TableOrder {
  column: string;
  direction: 1 | -1;
  object_field: string;
}

export interface GetSettingsForTableResponse {
  columns: TableConfigurationColumn[];
  order: TableOrder;
}

export interface GetSettingsForTableResponseData {
  data: GetSettingsForTableResponse;
}

const getSettingsUrl = (collectionName: string, tableName: string) =>
  `/api/settings/tables/${collectionName}/${tableName}`;

export const getSettingsForTable = async (
  objectOrUrl: GetSettingsForTableParams
) => {
  const url =
    typeof objectOrUrl === 'string'
      ? objectOrUrl
      : getSettingsUrl(objectOrUrl.collectionName, objectOrUrl.tableName);

  const response = await axiosInstance.get<GetSettingsForTableResponseData>(
    url
  );

  return response.data.data;
};
