import {
  AnalysisType,
  OPPORTUNITY,
} from 'components/dashboard/Metrics/constants';
import {
  AggregationFunction,
  MetricType,
  UnitType,
} from 'components/dashboard/Metrics/enums';
import {
  BIMetricFormulaNewborn,
  BIMetricSimpleNewborn,
} from 'components/dashboard/Metrics/metrics.types';

export enum OptionSections {
  TYPE = 'type',
  METRIC = 'metric',
  FUNNEL = 'funnel',
  TEMPLATE_FILTERS = 'templateFilters',
}

export enum CreationType {
  METRIC = 'metric',
  WIDGET = 'widget',
}

export const NOT_SAVED_METRIC = 'Not_saved_metric';

const BASE_METRIC_MOCK = {
  _id: NOT_SAVED_METRIC,
  column: undefined,
  filters: [],
  is_cumulative_sum: false,
  manager_aggregation_type: '',
  name: '',
  target_type: undefined,
  target_period: undefined,
  name_identifier: undefined,
};

const BASE_SIMPLE_METRIC_NEWBORN_MOCK = {
  ...BASE_METRIC_MOCK,
  aggregation_function: AggregationFunction.Sum,
  cumulative_sum_period: 'month',
  metadata: {
    type: MetricType.Simple,
  },
  object: OPPORTUNITY,
  properties: {},
  synthetic_metric: undefined,
};

export const SIMPLE_METRIC_NEWBORN_MOCK: BIMetricSimpleNewborn = {
  ...BASE_SIMPLE_METRIC_NEWBORN_MOCK,
  analysis_type: AnalysisType.LIVE,
  date_field: undefined,
};

export const SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK: BIMetricSimpleNewborn = {
  ...BASE_SIMPLE_METRIC_NEWBORN_MOCK,
  manager_aggregation_type: 'total_team_revenue',
  analysis_type: AnalysisType.HISTORICAL,
};

const BASE_FORMULA_METRIC_NEWBORN_MOCK = {
  ...BASE_METRIC_MOCK,
  aggregation_function: undefined,
  cumulative_sum_period: undefined,
  date_field: undefined,
  metadata: {
    type: MetricType.Formula,
  },
  object: '',
  properties: {
    metricUnit: {
      unit: '',
      type: UnitType.Prefix,
    },
  },
  synthetic_metric: '',
};

export const FORMULA_METRIC_NEWBORN_MOCK: BIMetricFormulaNewborn = {
  ...BASE_FORMULA_METRIC_NEWBORN_MOCK,
  analysis_type: AnalysisType.LIVE,
};

export const FORMULA_HISTORICAL_METRIC_NEWBORN_MOCK: BIMetricFormulaNewborn = {
  ...BASE_FORMULA_METRIC_NEWBORN_MOCK,
  manager_aggregation_type: 'total_team_revenue',
  analysis_type: AnalysisType.HISTORICAL,
};
