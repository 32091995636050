import { DataDescriptor } from 'components/dashboard/Metrics/metrics.types';

export const getGroupedFilters = (filters: DataDescriptor[]) =>
  Array.from(
    filters
      .reduce((acc, filter) => {
        const hasTableName = filter.name.includes('.');
        if (!hasTableName) {
          acc.set('No Group', [...(acc.get('No Group') || []), filter]);
        } else {
          const tableName = filter.name.split('.')[0];
          acc.set(tableName, [...(acc.get(tableName) || []), filter]);
        }
        return acc;
      }, new Map<string, DataDescriptor[]>())
      .entries()
  ).sort((a, b) => a[0].localeCompare(b[0]));
