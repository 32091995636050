import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  fetchAllMetrics,
  fetchAllTSMetrics,
  fetchAllWidgets,
  fetchTimeOptions,
  fetchQuarterForecastPeriodTimeOptions,
  fetchMonthForecastPeriodTimeOptions,
  fetchWeekForecastPeriodTimeOptions,
} from 'actions/revbi/metrics';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import { MetricsDashboard } from 'components/dashboard/Metrics/Dashboard/MetricsDashboard';
import { UsersByActivityProvider } from 'components/dashboard/Metrics/contexts/UsersByActivityContext';
import { dispatchIfNotAsked } from 'components/dashboard/Metrics/metrics.helpers';
import { BIDashboard } from 'components/dashboard/Metrics/metrics.types';
import { Partition } from 'navigation/tabs';
import {
  getMetricsListStatus,
  getMetricsTSListStatus,
  getMonthForecastPeriodOptionsStatus,
  getQuarterForecastPeriodOptionsStatus,
  getWeekForecastPeriodOptionsStatus,
  getTimeOptionsStatus,
  getWidgetsListStatus,
} from 'selectors/revbi/metrics';
import { fetchApi, QueryStatus } from 'utils/network';
import { RevBISettingsContext } from './contexts/RevBISettingsContext';

export const RevBiDashboardContainer: React.FC = () => {
  const { setPartition, clearContext } = useHeader();
  const dispatch = useDispatch();

  const match = useRouteMatch<{ id: string; tab_name: Partition }>();
  const selectedDashboardIdRef = useRef(match.params.id);
  const { isLoading } = useContext(RevBISettingsContext);

  const [loadDashboardStatus, setLoadDashboardStatus] =
    useState<QueryStatus>('notAsked');
  const [selectedDashboard, setSelectedDashboard] = useState<BIDashboard>();

  const metricsListStatus = useSelector(getMetricsListStatus);
  const historicalMetricsListStatus = useSelector(getMetricsTSListStatus);
  const widgetsListStatus = useSelector(getWidgetsListStatus);
  const timeOptionsStatus = useSelector(getTimeOptionsStatus);
  const quarterOptionsStatus = useSelector(
    getQuarterForecastPeriodOptionsStatus
  );
  const monthOptionsStatus = useSelector(getMonthForecastPeriodOptionsStatus);
  const weekOptionsStatus = useSelector(getWeekForecastPeriodOptionsStatus);

  const partition = match.params['tab_name'];

  useEffect(() => {
    setPartition(partition);

    dispatchIfNotAsked(dispatch, fetchAllMetrics, metricsListStatus);
    dispatchIfNotAsked(
      dispatch,
      fetchAllTSMetrics,
      historicalMetricsListStatus
    );
    dispatchIfNotAsked(dispatch, fetchAllWidgets, widgetsListStatus);
    dispatchIfNotAsked(dispatch, fetchTimeOptions, timeOptionsStatus);
    dispatchIfNotAsked(
      dispatch,
      fetchQuarterForecastPeriodTimeOptions,
      quarterOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      fetchMonthForecastPeriodTimeOptions,
      monthOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      fetchWeekForecastPeriodTimeOptions,
      weekOptionsStatus
    );

    return () => {
      clearContext();
    };
  }, []);

  useEffect(() => {
    if (
      match.params.id &&
      selectedDashboardIdRef.current !== match.params.id &&
      !isLoading
    ) {
      const abortController = new AbortController();

      fetchApi<void, BIDashboard>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${match.params.id}/complete`,
        queryMethod: 'get',
        setData: (result) => {
          setSelectedDashboard({
            ...result,
            id: result.id || result._id,
          });
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load dashboard: ${error}`);
        },
        setStatus: setLoadDashboardStatus,
        signal: abortController.signal,
      });

      return () => {
        abortController.abort();
      };
    }
  }, [match.params.id, isLoading]);

  return (
    <div className="container-dashboard">
      <UsersByActivityProvider
        includeDisabledUsers={
          selectedDashboard?.properties?.settings?.userFilter !== 'active'
        }
      >
        <MetricsDashboard
          key={selectedDashboard?.id}
          readOnly
          loadDashboardStatus={isLoading ? 'loading' : loadDashboardStatus}
          selectedDashboard={selectedDashboard}
          selectedDashboardIdRef={selectedDashboardIdRef}
          setSelectedDashboard={setSelectedDashboard}
          notClickableName
        />
      </UsersByActivityProvider>
    </div>
  );
};
