import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useModal } from 'components/modals/ModalContext/modal.context';
import BuIcon from 'components/UI/BuIcon';
import {
  MeddicField,
  StepWithValue,
} from 'components/dashboard/Forecast/MeddicSidePanel/MeddicSidePanel';
import { ValueType } from 'components/UI/common/TypedTable/TypedTable';
import BuButton from 'components/UI/BuButton';
import { getFeatureFlags, getUserLocalCurrency } from 'selectors';
import { putSalesProcess } from 'api/CallInsights';

import * as styles from './styles';
import {
  SalesProcessModal as SalesProcessModalType,
  Step,
  Task,
} from '../../types';
import { getIsTaskCompleted } from '../../helpers';

export const SalesProcessModalTitle: React.FC = () => {
  const { getPropsForScheme } = useModal<SalesProcessModalType>();

  const {
    salesProcessItem: { task = {} },
  } = getPropsForScheme('/sales-process') || {};

  const { name: taskName = '' } = task as Task;

  return <div className={styles.salesProcessModalTitle}>Update {taskName}</div>;
};

const SalesProcessModal: React.FC = () => {
  const queryClient = useQueryClient();
  const [updatedValues, setUpdatedValues] = useState<Step[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { getPropsForScheme, closeModal } = useModal<SalesProcessModalType>();
  const companyCurrency = useSelector(getUserLocalCurrency);
  const { task_completion_enabled } = useSelector((state) =>
    getFeatureFlags(state)
  );

  const {
    salesProcessItem: { task = {}, status, owner_id, id: task_id },
    callId,
    user_id,
    completeTaskMutation,
  } = getPropsForScheme('/sales-process') || {};

  const {
    name: taskName = '',
    opportunity_id = '',
    steps: initialSteps = [],
  } = task as Task;

  useEffect(() => {
    setUpdatedValues(initialSteps);
  }, [initialSteps]);

  const changeSalesProcessMutation = useMutation({
    mutationFn: ({
      steps,
    }: {
      steps: Record<string, Step['new_value']>;
      isCompleteTask: boolean;
    }) =>
      putSalesProcess({
        opportunity_id,
        sales_process: taskName,
        steps,
      }),
    onMutate: ({ isCompleteTask }) => {
      setIsSaving(true);

      toast.warn(
        isCompleteTask
          ? 'Saving sales process and completing task...'
          : 'Saving sales process...',
        { position: 'bottom-left' }
      );
    },
    onError: (_, { isCompleteTask }) => {
      setIsSaving(false);

      toast.error(
        isCompleteTask
          ? 'Error saving sales process and completing task.'
          : 'Error saving sales process.',
        { position: 'bottom-left' }
      );
    },
    onSuccess: (_, { isCompleteTask }) => {
      setIsSaving(false);

      toast.success(
        isCompleteTask
          ? 'Sales process saved successfully and task completed.'
          : 'Sales process saved successfully.',
        { position: 'bottom-left' }
      );

      closeModal('/sales-process', () => ({}));

      if (getIsSalesProcessOwner() && isCompleteTask) {
        completeTaskMutation.mutate({
          task_id: task_id,
          isTaskCompleted,
          noToast: true,
        });
        return;
      }

      queryClient.invalidateQueries(['callTodoData', callId]);
    },
  });

  const handleFieldChange = (step: Step) => (value: ValueType) => {
    setUpdatedValues(
      updatedValues.map((item) =>
        item.step_object_field === step.step_object_field
          ? { ...step, new_value: value }
          : item
      )
    );
  };

  const getIsSalesProcessOwner = () => owner_id === user_id;

  const isTaskCompleted = getIsTaskCompleted(status);

  const showCompleteTaskButton =
    getIsSalesProcessOwner() && !isTaskCompleted && !!task_completion_enabled;

  const changeSalesProcess = (isCompleteTask: boolean = false) => {
    const stepsPayload = updatedValues.reduce(
      (acc: Record<string, Step['new_value']>, step: Step) => {
        acc[step.field_info.step_name] = step.new_value;
        return acc;
      },
      {} as Record<string, Step['new_value']>
    );

    changeSalesProcessMutation.mutate({
      steps: stepsPayload,
      isCompleteTask,
    });
  };

  // Planned feature, will have this in the future
  // const handleExplanationClicked = (step: Step) => {
  //   const originalNewValue = initialSteps.find(
  //     (item) => item.step_object_field === step.step_object_field
  //   )?.new_value;

  //   handleFieldChange(step)(originalNewValue as ValueType);
  // };

  return (
    <div className={styles.salesProcessModalWrapper}>
      <div className="sales-process-modal-title">
        Suggested {taskName} Updates. Do you want to update it?
      </div>

      <div className="sales-process-modal-content">
        <div className="left-side">
          <div className="left-side-title">Existing values</div>

          <div className="left-side-fields">
            {initialSteps.map((step) => (
              <MeddicField
                key={step.step_object_field}
                readOnly
                step={
                  {
                    ...step.field_info,
                    editable: false,
                    value: step.current_value,
                  } as StepWithValue
                }
                companyCurrency={companyCurrency}
              />
            ))}
          </div>
        </div>

        <div className="middle-arrow">
          <BuIcon name={BoostUpIcons.ChevronRight} />
        </div>

        <div className="right-side">
          <div className="right-side-title">New values</div>

          <div className="right-side-fields">
            {updatedValues.map((step) => (
              <MeddicField
                key={step.step_object_field}
                readOnly={false}
                step={
                  {
                    ...step.field_info,
                    editable: true,
                    explanation: step.explanation,
                    value: step.new_value,
                  } as StepWithValue
                }
                onChange={handleFieldChange(step)}
                companyCurrency={companyCurrency}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="sales-process-modal-footer">
        <BuButton
          secondary
          disabled={isSaving}
          onClick={() => closeModal('/sales-process', () => ({}))}
        >
          Cancel
        </BuButton>

        <BuButton
          secondary={showCompleteTaskButton}
          onClick={() => changeSalesProcess(false)}
          disabled={isSaving}
        >
          Submit
        </BuButton>

        {showCompleteTaskButton && (
          <BuButton
            onClick={() => changeSalesProcess(true)}
            disabled={isSaving}
          >
            Submit and complete task
          </BuButton>
        )}
      </div>
    </div>
  );
};

export default SalesProcessModal;
