import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useIsMutating } from '@tanstack/react-query';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  isBIMetricFormula,
  isBIMetricSimple,
} from 'components/dashboard/Metrics/Create/utils';
import { MetricsWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/MetricsWidgetControls';
import {
  RefreshButtonConfiguration,
  WidgetHeader,
} from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { WidgetVisualization } from 'components/dashboard/Metrics/Widget/Visualization/WidgetVisualization';
import {
  everyMetricsHaveDateField,
  getMetricsNameAndValueUsedInFormula,
  getSyntheticMetricData,
  getWidgetMetricDisplayNameMap,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import useHierarchicalWidgetFetching from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching';
import { useFormulaMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useFormulaMetricsList';
import {
  checkMetricValidity,
  createDrillDownParams,
  openDrillDownModal,
  openTableDrillDownModal,
  openTotalDrilldownModal,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { SyntheticMetricDataWidgetState } from 'components/dashboard/Metrics/Widget/types';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  GENERIC_TIME_INTERVAL_PIVOTS,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
  BIMetricUnion,
  BIMetricsMap,
  BIPivotDescriptor,
  BIWidget,
  DrillDownParams,
  ExternalFieldsRequestParams,
} from 'components/dashboard/Metrics/metrics.types';
import { getMetricsList } from 'selectors/revbi/metrics';
import { getFeatureFlag } from 'selectors/settings';
import { fetchApi } from 'utils/network';
import { OnChartDataClick } from './Chart/WidgetChart.types';
import { ConfigClickExtraData } from './Table/helpers/columnsHelpers';
import { useCacheMutation } from './hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import {
  OpenRevBiDrilldownModal,
  useOpenRevBiDrilldownModal,
} from './hooks/useRevBiDrilldownModal';
import { canOpenADrilldown } from '../metrics.helpers';

interface Props {
  metric: BIMetricUnion;
  widget: BIWidget;
  setWidget: Dispatch<SetStateAction<BIWidget>>;
}

export const MetricsWidget: React.FC<Props> = ({
  metric,
  widget,
  setWidget,
}) => {
  const dispatch = useDispatch();

  const { drilldownTableSettings: drilldown_table_settings, hierarchyAlias } =
    useContext(RevBISettingsContext);
  const supportedCustomObjects = Object.keys(drilldown_table_settings);

  const metricDisplayNames = getWidgetMetricDisplayNameMap(widget);

  const openRevBiDrilldownModal = useOpenRevBiDrilldownModal();

  const metricsList = useSelector(getMetricsList);
  const { revbiEmailsDrilldownEnabled, revbiEventsDrilldownEnabled } =
    useSelector((state) => ({
      revbiEmailsDrilldownEnabled: getFeatureFlag(
        state,
        'revbi_emails_drilldown_enabled'
      ),
      revbiEventsDrilldownEnabled: getFeatureFlag(
        state,
        'revbi_events_drilldown_enabled'
      ),
    }));

  const metricKey = metric._id;

  const metricsInUse: BIMetricsMap = {
    [metricKey]: metric as BIMetricCreated,
  };

  const metricToChartType = useMemo(() => {
    if (widget?.properties?.metricToChartType?.length) {
      return widget?.properties?.metricToChartType?.filter(
        (mtcItem) => metricKey == mtcItem.metricId
      );
    } else {
      return [
        {
          chartType: VisualizationType.Column,
          metricId: metricKey,
        },
      ];
    }
  }, [widget.properties?.metricToChartType]);

  const isTableVisualization = metricToChartType.some(
    (el) => el.chartType === 'table'
  );

  const [availablePivots, setAvailablePivots] = useState<BIPivotDescriptor[]>(
    []
  );

  const usedFormulaMetricIds: string[] = useMemo(
    () =>
      parseFormulaToMetricsIdArray(
        (metric as BIMetricFormula).synthetic_metric
      ),
    [(metric as BIMetricFormula).synthetic_metric]
  );

  const { data: usedFormulaMetricsMap } =
    useFormulaMetricsList(usedFormulaMetricIds);

  /**
   * Hook to fetch available pivot options per created metric.
   */
  useEffect(() => {
    const objectsSet = new Set<string>();
    const dateFieldsSet = new Set<string>();

    const abortControllerFirstPivot = new AbortController();
    const abortControllerSecondPivot = new AbortController();

    if (isBIMetricFormula(metric)) {
      Object.keys(usedFormulaMetricsMap).forEach((element) => {
        const metricData = usedFormulaMetricsMap[element];

        if (isBIMetricSimple(metricData)) {
          objectsSet.add(metricData.object);
          dateFieldsSet.add(metricData.date_field?.name ?? '');
        }
      });
    } else if (isBIMetricSimple(metric)) {
      objectsSet.add(metric.object);
      dateFieldsSet.add(metric.date_field?.name ?? '');
    }

    if (objectsSet.size) {
      const queryParams: ExternalFieldsRequestParams = {
        table_names: [...objectsSet],
      };

      fetchApi<ExternalFieldsRequestParams, BIPivotDescriptor[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/columns/pivots`,
        queryMethod: 'get',
        queryParams: queryParams,
        setData: (result) => {
          let cleanedResult = everyMetricsHaveDateField(widget)
            ? [...GENERIC_TIME_INTERVAL_PIVOTS, ...result]
            : [...result];

          setAvailablePivots(cleanedResult);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load second pivot columns: ${error}`);
        },
        signal: abortControllerSecondPivot.signal,
      });
    }

    return () => {
      setAvailablePivots([]);
      abortControllerFirstPivot.abort();
      abortControllerSecondPivot.abort();
    };
  }, [
    (metric as BIMetricSimple)?.object,
    (metric as BIMetricSimple)?.date_field,
    widget.metric_list,
    JSON.stringify(usedFormulaMetricsMap),
  ]);

  const isMetricValid = checkMetricValidity(metric);

  const {
    treeWidget,
    status: hierarchicalStatus,
    isTableRefetching,
    isTreeWidgetCompatibleWithChartVisualization,
    addSubTreeToFetch,
    refetchLoadedSubtrees,
  } = useHierarchicalWidgetFetching({
    widgetConfiguration: widget,
    hookEnabled: isMetricValid,
    widgetType: isTableVisualization ? 'table' : 'chart',
    widgetAction: 'edition',
  });

  const { fetchPivotDelayedBy } = useCacheMutation(widget._id ?? '');

  const isTargetMetric = (metric as BIMetricSimple)?.object === TARGET;

  const openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal = (
    modalConfig
  ) => {
    openRevBiDrilldownModal(modalConfig, () => {});
  };

  const handleTableDataClick = (
    column: IColumn,
    row: IRow,
    extraData: ConfigClickExtraData
  ): void => {
    let clickedMetric = metric as BIMetricCreated;

    if (extraData.subValueClicked) {
      const subValueMetricId =
        column.config.subValue?.relativeField?.split('|')[0];
      clickedMetric =
        metricsList.find(({ _id }) => _id === subValueMetricId) ||
        clickedMetric;
    }

    if (clickedMetric) {
      openTableDrillDownModal(
        clickedMetric,
        column,
        undefined,
        widget?.group_by?.[0]?.name || '',
        revbiEmailsDrilldownEnabled,
        revbiEventsDrilldownEnabled,
        row,
        widget,
        '',
        dispatch,
        openRevBiDrilldownModalWithOnClose,
        drilldown_table_settings
      );
    }
  };

  const handleChartDataClick: OnChartDataClick = (pointEvent) => {
    const metricFieldNameClicked = pointEvent.metricId;

    if (metricFieldNameClicked) {
      const metricClicked = metricsInUse[metricFieldNameClicked];
      if (metricClicked) {
        const hasClickAction = canOpenADrilldown(
          metricClicked,
          supportedCustomObjects
        );
        if (!hasClickAction) {
          return;
        }
        // Remapping as we're not modifying openDrillDownModal
        const selectedValue = {
          metricId: pointEvent.metricId,
          pivot1Id: pointEvent.pivotValues[0],
          pivot2Id: pointEvent.pivotValues[1],
        };

        openDrillDownModal(
          widget,
          selectedValue,
          metric as BIMetricCreated,
          '',
          revbiEmailsDrilldownEnabled,
          revbiEventsDrilldownEnabled,
          undefined,
          // We removed v2 so this is not needed anymore
          // openTableDrillDownModal Should be refactored
          treeWidget,
          widget?.group_by?.length ?? 0,
          widget?.group_by?.[0],
          widget?.group_by?.[1],
          metricToChartType?.[0]?.chartType || VisualizationType.Column,
          dispatch,
          openRevBiDrilldownModalWithOnClose,
          hierarchyAlias,
          drilldown_table_settings
        );
      }
    }
  };

  const handleTotalsClick = (metricId: string): void => {
    const clickedMetric = metric as BIMetricCreated;

    const drilldownParams: DrillDownParams = createDrillDownParams(
      clickedMetric,
      [],
      '',
      widget
    );

    if ((clickedMetric as BIMetricFormula).synthetic_metric) {
      const totals = treeWidget.totals;

      const parsedClickedMetricFormulaAsArray: string[] =
        parseFormulaToMetricsIdArray(
          (clickedMetric as BIMetricFormula).synthetic_metric
        );

      const syntheticMetricData: SyntheticMetricDataWidgetState =
        getSyntheticMetricData(
          parsedClickedMetricFormulaAsArray,
          clickedMetric as BIMetricFormula
        );

      let clickedValue = totals[metricId];

      openSyntheticMetricDetailsModal(
        widget.name || 'Drill Down',
        drilldownParams,
        syntheticMetricData,
        {
          y: clickedValue,
          metricsValues: getMetricsNameAndValueUsedInFormula(
            parsedClickedMetricFormulaAsArray,
            totals || {},
            metricDisplayNames
          ),
        },
        openRevBiDrilldownModalWithOnClose,
        drilldown_table_settings
      );
    } else {
      openTotalDrilldownModal(
        clickedMetric,
        drilldownParams,
        dispatch,
        openRevBiDrilldownModalWithOnClose,
        drilldown_table_settings
      );
    }
  };

  const handleUpdateWidget = (changes: Partial<BIWidget>) => {
    setWidget((prev) => ({
      ...prev,
      ...changes,
    }));
  };

  const cleanCacheAndRefresh = () => {
    if (!isTableRefetching) {
      fetchPivotDelayedBy(widget.group_by.length, refetchLoadedSubtrees);
    }
  };

  // checks if any mutation is running to lock the refresh button.
  const isMutatingInProgress = useIsMutating(['updateObjectField']);

  const refreshButtonConfiguration = useMemo<RefreshButtonConfiguration>(
    () => ({
      cacheDate: '',
      showRefreshButton: isTableVisualization,
      refreshButtonLoading: !!isTableRefetching,
      disabled: isMutatingInProgress > 0 || hierarchicalStatus === 'loading',
      onRefreshData: cleanCacheAndRefresh,
    }),
    [
      isTableVisualization,
      isTableRefetching,
      isMutatingInProgress,
      cleanCacheAndRefresh,
      hierarchicalStatus,
    ]
  );

  if (!isTreeWidgetCompatibleWithChartVisualization) {
    const newMetricToChartType = widget.properties?.metricToChartType?.map(
      (el) => {
        el.chartType = VisualizationType.Table;
        return el;
      }
    );

    setWidget({
      ...widget,
      properties: {
        ...widget.properties,
        metricToChartType: newMetricToChartType,
      },
    });
    toast.warn(
      'The dataset is too large for chart display. Visualization switched to table mode.'
    );
  }

  return (
    <>
      <WidgetContainer isDashboard={false} isMetricsPreview={true}>
        <WidgetHeader
          isCreateEditMetric
          name={widget.name}
          data-testing="widget-header"
          refreshButtonConfig={refreshButtonConfiguration}
          alternativeVisibility={
            !!widget.advanced_configurations?.user_visibility_name
          }
        />

        <MetricsWidgetControls
          isTargetMetricOnlyWidget={isTargetMetric}
          hasTargetMetric={isTargetMetric}
          availablePivots={availablePivots}
          widget={widget}
          metricToChartType={metricToChartType}
          setWidget={setWidget}
          data-testing="metrics-widget-controls"
        />

        <WidgetVisualization
          showControls
          isTableRefetching={isTableRefetching}
          widgetDataStatus={hierarchicalStatus}
          metricsInUse={metricsInUse}
          metricToChartType={metricToChartType}
          widget={widget}
          widgetData={treeWidget}
          onUpdateWidget={handleUpdateWidget}
          onChartDataClick={handleChartDataClick}
          onTableDataClick={handleTableDataClick}
          onTotalsClick={handleTotalsClick}
          addSubTreeToFetch={addSubTreeToFetch}
          data-testing="metrics-widget-visualization"
        />
      </WidgetContainer>
    </>
  );
};
