import classNames from 'classnames';
import { css } from 'emotion';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import * as R from 'ramda';
import { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { ColumnTypes } from '..';
import {
  CurrencyMetadata,
  IColumn,
  IDataCellProps,
  IRow,
  IRowWithChangesSince,
  TypedTableCellConfig,
  ValueProp,
  ValueType,
} from '../../TypedTable';
import MultiCurrencyIconTooltip from './MultiCurrencyIconTooltip';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import styles from 'components/UI/DealsFlatTableTS/Table/styles';
import {
  DropDownCellConfig,
  isOptionsFunction,
} from 'components/UI/common/TypedTable/renderers/DropDownCell';
import * as selectors from 'selectors';

export interface IDeltaValueType {
  value: null | ValueType;
  prev_value: null | ValueType;
  updated_at: string;
  latest_updated_at: null | ValueType;
  latest_value: null | ValueType;
}

export const getValueFromDelta = (field: ValueProp): null | ValueType => {
  if (field instanceof Object) {
    return (field as IDeltaValueType).value;
  }

  return field;
};

const deltaContainer = css`
  display: flex;
  align-items: center;
  gap: 10px;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.icon {
    font-size: 0;
  }

  .children {
    width: 95%;

    &.has-indicator {
      width: 85%;
    }
  }
`;

const popupWidth = css`
  min-width: 300px;
  max-height: 280px;
`;

const arrowWrapper = css`
  align-self: center;
  text-align: center;
  line-height: 1;
  font-size: 6px;
  padding: 5px;
  border-radius: 4px;

  &.up {
    background-color: var(--bu-green-500);
    color: var(--bu-white);
  }

  &.down {
    background-color: var(--bu-red-400);
    color: var(--bu-white);
  }
`;

const deltaIconSpaceHolder = css`
  width: 24px;
`;

const deltaIconSpaceHolderNoMulticurrency = css`
  width: 17.5px;
`;

const multicurrencyPlaceholder = css`
  width: 5%;
  padding: 5px 0;
  margin-right: 13px;

  &::after {
    content: '';
  }
`;

const noDeltaWrapper = css`
  width: 100%;
`;

const defaultFormatter = (value: ValueProp) => `${value}`;

interface IDeltaValueProps extends IDataCellProps {
  currencyMetadata?: CurrencyMetadata;
  formatter?: TypedTableCellConfig['formatter'];
  formatterExtraOptions?: ValueProp[];
  row: IRowWithChangesSince;
}

const dateToNumber = (s: string): number => new Date(s).getTime();
const getArrIndex = (c: string, arr: string[] = []): number => arr.indexOf(c);

enum Indicator {
  UP = 'up',
  DOWN = 'down',
}
const getIndicatorType = ({
  cell,
  column,
  row,
  rows,
}: {
  cell: IDeltaValueType;
  column: IColumn;
  row: IRow;
  rows: IRow[];
}) => {
  // Splecial case when value is number and prev_value is null
  // Considering null as 0
  if (
    !R.isNil(cell.value) &&
    cell.prev_value === null &&
    isNumber(cell.value)
  ) {
    if (cell.value < 0) {
      return Indicator.DOWN;
    } else {
      return Indicator.UP;
    }
  }

  if (!R.isNil(cell.value) && !R.isNil(cell.prev_value)) {
    if (isNumber(cell.value) || cell.value === null) {
      const value = cell.value ?? 0;
      if (value < cell.prev_value) {
        return Indicator.DOWN;
      } else if (value > cell.prev_value) {
        return Indicator.UP;
      }
    } else if (column.type === ColumnTypes.DATE) {
      const value = dateToNumber(cell.value as string);
      const prevValue = dateToNumber(cell.prev_value as string);

      if (value < prevValue) {
        return Indicator.UP;
      } else if (value > prevValue) {
        return Indicator.DOWN;
      }
    } else if (column.type === ColumnTypes.DROPDOWN) {
      const config = column.config as DropDownCellConfig;
      const options = (
        (config.options &&
          (isOptionsFunction(config.options)
            ? config.options({ column, row, rows })
            : config.options)) ||
        []
      ).map((item) => item.text);
      const value = getArrIndex(cell.value as string, options as string[]);
      const prevValue = getArrIndex(
        cell.prev_value as string,
        options as string[]
      );

      if (value < prevValue) {
        return Indicator.DOWN;
      } else if (value > prevValue) {
        return Indicator.UP;
      }
    } else {
      return Indicator.UP;
    }
  }

  return null;
};

const DeltaValueCell = ({
  column,
  row,
  rows,
  currencyMetadata,
  children,
  formatter = defaultFormatter,
  formatterExtraOptions = [],
}: PropsWithChildren<IDeltaValueProps>) => {
  const cell = R.path(column.field.split('.'), row) as IDeltaValueType;

  /* TODO: get currency from the column config */
  const userCurrency = useSelector(selectors.getUserLocalCurrency);
  const isMulticurrency = column.config.isMulticurrency;

  // extract the original currency and value from the currency metadata
  const { original_currency: originalCurrency, original_value: originalValue } =
    currencyMetadata ?? {};

  // Check if any other row has multicurrency icon to put a placeholder;
  const showMulticurrencyPlaceholder = useMemo(() => {
    if (!column.config.isMoney || !isMulticurrency) return false;

    if (isMulticurrency) return true;

    return rows.some(
      (_row) =>
        (_row.crm_metadata as any)?.currency &&
        (_row.crm_metadata as any)?.currency !== userCurrency
    );
  }, [column, rows]);

  if (!column.delta) {
    return (
      <div
        className={classNames(deltaContainer, {
          left: column.align === 'left',
          right: column.align === 'right',
          center: column.align === 'center',
        })}
      >
        {column.config.isMoney &&
        isMulticurrency &&
        originalCurrency &&
        originalValue ? (
          <MultiCurrencyIconTooltip
            column={column}
            row={row}
            originalCurrency={originalCurrency}
            originalValue={originalValue}
          />
        ) : showMulticurrencyPlaceholder ? (
          <div className={multicurrencyPlaceholder}></div>
        ) : null}
        <div className={noDeltaWrapper}>{children}</div>
      </div>
    );
  }

  const showDeltaPlaceholder = !!column.delta;

  const customDeltaTooltip = useMemo(
    () =>
      cell instanceof Object &&
      column.config.deltaTooltip &&
      column.config.deltaTooltip(column, row),
    [column, cell, row]
  );

  if (!(cell instanceof Object)) {
    return (
      <div
        className={classNames(deltaContainer, {
          left: column.align === 'left',
          right: column.align === 'right',
          center: column.align === 'center',
        })}
      >
        {column.config.isMoney &&
        isMulticurrency &&
        originalCurrency &&
        originalValue ? (
          <MultiCurrencyIconTooltip
            column={column}
            row={row}
            originalCurrency={originalCurrency}
            originalValue={originalValue}
          />
        ) : showMulticurrencyPlaceholder ? (
          <div className={multicurrencyPlaceholder}></div>
        ) : null}
        <div
          className={classNames('children', {
            'has-indicator': showDeltaPlaceholder,
          })}
        >
          {children}
        </div>
        <div
          className={classNames({
            [deltaIconSpaceHolder]:
              column.type !== 'score' &&
              showDeltaPlaceholder &&
              showMulticurrencyPlaceholder,

            // Because the multicurrency placeholder changes the layout of the cell
            // we need to adjust the width of the delta icon space holder differently
            [deltaIconSpaceHolderNoMulticurrency]:
              column.type !== 'score' &&
              showDeltaPlaceholder &&
              !showMulticurrencyPlaceholder,
          })}
        />
      </div>
    );
  }

  const indicatorType = getIndicatorType({ cell, column, row, rows });

  const trigger =
    cell.prev_value !== undefined && indicatorType !== null ? (
      <div className={classNames(styles.pointer, arrowWrapper, indicatorType)}>
        {indicatorType === Indicator.UP ? (
          <BuIcon name={BoostUpIcons.ChangeUp} />
        ) : (
          <BuIcon name={BoostUpIcons.ChangeDown} />
        )}
      </div>
    ) : (
      <div
        className={classNames({
          [deltaIconSpaceHolder]:
            column.type !== 'score' && showDeltaPlaceholder,
          'has-multicurrency-icon': showMulticurrencyPlaceholder,
        })}
      />
    );

  const changesSinceCopy = row.changesSinceCopy;
  const changesSinceDate = row.changesSinceDate;
  const currentValue = cell.value;

  const previousChangeBasedOnChangesSince = cell.prev_value;

  const prevIsNull = cell.prev_value === null;
  const latestChangeDate = cell.latest_updated_at;
  const latestUpdateFrom = cell.latest_value;

  return (
    <div
      className={classNames(deltaContainer, {
        left: column.align === 'left',
        right: column.align === 'right',
        center: column.align === 'center',
      })}
    >
      {column.config.isMoney &&
      isMulticurrency &&
      ((row.crm_metadata &&
        (row.crm_metadata as any).currency &&
        (row.crm_metadata as any).currency !== userCurrency) ||
        (originalCurrency && originalValue)) ? (
        <MultiCurrencyIconTooltip
          column={column}
          row={row}
          originalCurrency={originalCurrency}
          originalValue={originalValue}
        />
      ) : showMulticurrencyPlaceholder ? (
        <div className={multicurrencyPlaceholder}></div>
      ) : null}
      <div
        className={classNames('children', {
          'has-indicator': indicatorType != null,
        })}
      >
        {children}
      </div>
      {indicatorType != null ? (
        <Popup
          trigger={trigger}
          position={column.config.popupPosition || 'bottom center'}
          mouseEnterDelay={500}
          offset={[0, 0]}
        >
          {customDeltaTooltip !== undefined ? (
            customDeltaTooltip
          ) : (
            <div className={classNames(styles.popupText, popupWidth)}>
              <div>
                Changed from{' '}
                <span className={styles.popupTextBold}>
                  {prevIsNull
                    ? '-'
                    : formatter(
                        previousChangeBasedOnChangesSince,
                        ...formatterExtraOptions
                      )}
                </span>{' '}
                to{' '}
                <span className={styles.popupTextBold}>
                  {formatter(currentValue, ...formatterExtraOptions)}
                </span>
              </div>
              <div>
                Delta is based on Changes Since {changesSinceCopy} (
                <span className={styles.popupTextBold}>
                  {changesSinceDate})
                </span>
              </div>
              {latestChangeDate && (
                <div>
                  Latest Change: on{' '}
                  <span className={styles.popupTextBoldNoUppercase}>
                    {moment(latestChangeDate as string).format("MMM Do 'YY")}
                  </span>
                  , previous value was{' '}
                  <span className={styles.popupTextBold}>
                    {formatter(latestUpdateFrom, ...formatterExtraOptions)}
                  </span>
                </div>
              )}
            </div>
          )}
        </Popup>
      ) : (
        <div
          className={classNames({
            [deltaIconSpaceHolder]:
              column.type !== 'score' && showDeltaPlaceholder,
            'has-multicurrency-icon': showMulticurrencyPlaceholder,
          })}
        />
      )}
    </div>
  );
};

export default DeltaValueCell;
