import React, { createContext, useContext, useState, ReactNode } from 'react';

import { Segment } from '../../types';

interface TranscriptContextType {
  isAutoscrollActive: boolean;
  setIsAutoscrollActive: (value: boolean) => void;
  scrollToIndex: number | undefined;
  setScrollToIndex: (index: number | undefined) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  searchedIndexHighlighted: number;
  setSearchedIndexHighlighted: (index: number) => void;
  searchedSegments: Segment[];
  setSearchedSegments: (segments: Segment[]) => void;
  resetSearch: () => void;
  isSearchActive: boolean;
  setIsSearchActive: (value: boolean) => void;
}

const TranscriptContext = createContext<TranscriptContextType | undefined>(
  undefined
);

export const TranscriptProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAutoscrollActive, setIsAutoscrollActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(
    undefined
  );
  const [searchText, setSearchText] = useState('');
  const [searchedIndexHighlighted, setSearchedIndexHighlighted] = useState(0);
  const [searchedSegments, setSearchedSegments] = useState<Segment[]>([]);

  const resetSearch = () => {
    setSearchedSegments([]);
    setSearchText('');
    setSearchedIndexHighlighted(0);
    setScrollToIndex(undefined);
    setIsSearchActive(false);
  };

  return (
    <TranscriptContext.Provider
      value={{
        isAutoscrollActive,
        setIsAutoscrollActive,
        isSearchActive,
        setIsSearchActive,
        scrollToIndex,
        setScrollToIndex,
        searchText,
        setSearchText,
        searchedIndexHighlighted,
        setSearchedIndexHighlighted,
        searchedSegments,
        setSearchedSegments,
        resetSearch,
      }}
    >
      {children}
    </TranscriptContext.Provider>
  );
};

export const useTranscriptContext = (): TranscriptContextType => {
  const context = useContext(TranscriptContext);
  if (!context) {
    throw new Error(
      'useTranscriptContext must be used within a TranscriptProvider'
    );
  }
  return context;
};
