import { ALL_TIME_VALUE } from 'components/dashboard/Metrics/constants';
import {
  DataDescriptor,
  BIMetricsFilter,
} from 'components/dashboard/Metrics/metrics.types';

export const changeDateValue = (
  newValue: string[],
  operator: string = 'in',
  filters: BIMetricsFilter[],
  filterIndex: number,
  widgetFilter: DataDescriptor
): BIMetricsFilter[] => {
  const newFilters = filters || [];

  if (newValue[0] === ALL_TIME_VALUE) {
    newFilters.splice(filterIndex, 1);
  } else {
    newFilters[filterIndex !== -1 ? filterIndex : (filters?.length as number)] =
      {
        column: widgetFilter,
        operator: operator,
        value: newValue,
      };
  }

  return newFilters;
};

export const updateFilters = (
  filters: BIMetricsFilter[],
  isEmptyFilters: boolean,
  filterIndex: number,
  widgetFilter: DataDescriptor,
  operator: string,
  value: string | string[] | number
): BIMetricsFilter[] | undefined => {
  const newFilters = filters || [];

  if (isEmptyFilters) {
    if (filterIndex !== -1) {
      newFilters.splice(filterIndex, 1);
    } else {
      return undefined;
    }
  } else {
    newFilters[filterIndex !== -1 ? filterIndex : (filters?.length as number)] =
      {
        column: widgetFilter,
        operator: operator,
        value: value,
      };
  }

  return newFilters;
};

export const extractTableNameFromFilter = (filter: DataDescriptor) => {
  const hasTableName = filter.name.includes('.');

  if (!hasTableName) return filter.name;

  const tableName = filter.name.split('.')[0];

  return `${formatGroup(tableName)} - ${filter.label}`;
};

export const formatGroup = (group: string) => {
  return group
    .replace('__c', ' C')
    .replace('_', ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
