import { isBIMetricFormula, isBIMetricSimple } from '../Create/utils';
import { DrilldownTableSettings } from '../contexts/RevBISettingsContext';

import {
  FORMULA_PARSER_REGEXP,
  NUMBERS_REGEXP,
} from 'components/UI/BuFormulaTextField/constants';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  DataObject,
  SyntheticMetricDataWidgetState,
} from 'components/dashboard/Metrics/Widget/types';
import { UnitType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIMetricUnion,
  BIWidget,
  DrillDownParams,
  FormulaMetricValuesAsChartValue,
  SelectedValue,
} from 'components/dashboard/Metrics/metrics.types';
import { OpenRevBiDrilldownModal } from './hooks/useRevBiDrilldownModal';

export const getUnits = (type: UnitType, metric?: BIMetricCreated): string => {
  const isSynthetic = metric && isBIMetricFormula(metric);

  const isUnitsExist = metric?.properties && metric?.properties.metricUnit;
  const isTypesMatches = type === metric?.properties?.metricUnit?.type;

  if (isSynthetic && isUnitsExist && isTypesMatches) {
    return metric?.properties?.metricUnit?.unit || '';
  }

  return '';
};

export const getYValueFromRow = (
  row: IRow,
  clickedMetric: BIMetricCreated,
  column: IColumn
) => {
  let path: string;
  // If has extra header, the row has a different structure
  // As each metric is appended with the date
  // So to find the value we have to search for the metric name plus the date
  if (column.extraHeader) {
    path = column.field;
  } else {
    // If no extra header, is not date pivoted
    // So we can just search for the metric id
    // we can't always use the column field as it can
    // be a sub value metric, so the field is the main metric id
    path = clickedMetric._id;
  }

  // We don't have to worry about the case where the subvalue is on a date pivoted table
  // so the case were both extraHeader and subValue are true is not handled, as we don't
  // support that
  const value = Number(row[path]);

  return !isNaN(value) ? value : 0;
};

/**
 *
 * @param dateAppendix - When using date as first pivot, metrics are appended with date
 *  as we need to display as columns instead of pivots.To this point that date was
 *  already extracted. But the row object still contains the original metric names.
 *  if was a date keyed metric, metricAppendix should contain the date, otherwise it will be undefined
 */
export const getMetricsNameAndValueUsedInFormula = (
  metricsInFormula: string[],
  row: DataObject,
  metricDisplayNames: WidgetMetricDisplayNameMap,
  dateAppendix?: string
): FormulaMetricValuesAsChartValue[] => {
  const rowAsEntries = Object.entries(row || {});
  const nonDuplicateMetricsInFormula = Array.from(new Set(metricsInFormula));

  return nonDuplicateMetricsInFormula.map((metricInFormula) => {
    const metricDateAppendix = dateAppendix ? `|${dateAppendix}` : '';
    const metricKey = `$${metricInFormula}` + metricDateAppendix;
    const metricInRow = rowAsEntries.find(([key]) => key.includes(metricKey));

    const metricValue = metricInRow && (metricInRow[1] as string | number);
    const keyInRow = metricInRow && metricInRow[0];
    const deltaValue = !!keyInRow
      ? (row?.metricDeltas?.[keyInRow] as number)
      : undefined;

    return {
      id: metricInFormula,
      metricName: metricDisplayNames[metricInFormula] || '',
      value: metricValue,
      deltaValue: deltaValue,
    };
  });
};

export const getSyntheticMetricData = (
  parsedClickedMetricFormulaAsArray: string[],
  clickedMetric: BIMetricFormula
): SyntheticMetricDataWidgetState => {
  return {
    parsedFormula: parsedClickedMetricFormulaAsArray.join(''),
    syntheticMetric: clickedMetric,
  };
};

export const openSyntheticMetricDetailsModal = (
  title: string,
  drilldownParams: DrillDownParams,
  syntheticMetricData: SyntheticMetricDataWidgetState,
  selectedValue: SelectedValue,
  openSyntheticModal: OpenRevBiDrilldownModal,
  drilldownTableSettings?: Record<string, DrilldownTableSettings>,
  changeInterval?: string
): void => {
  // TODO: proper check of data consistency
  if (
    syntheticMetricData.parsedFormula &&
    selectedValue.metricsValues &&
    syntheticMetricData.syntheticMetric
  ) {
    const props = {
      title: title,
      formula: syntheticMetricData.syntheticMetric?.synthetic_metric || '',
      conditions: syntheticMetricData.syntheticMetric?.filters,
      valuesByMetrics: selectedValue.metricsValues,
      drilldownParams: drilldownParams,
      metric: syntheticMetricData.syntheticMetric,
      selectedValue,
      drilldownTableSettings,
      changeInterval,
    };

    openSyntheticModal({
      scheme: '/revbi-formula-modal',
      props,
    });
  }
};

export function parseFormulaToMetricsIdArray(formula: string): string[] {
  const result = [];
  let match;
  while ((match = FORMULA_PARSER_REGEXP.exec(formula)) !== null) {
    let isMetric = match[0].length > 1 && !NUMBERS_REGEXP.test(match[0]);

    if (isMetric) {
      const metricId = match[0].replace(new RegExp(/[{}]/gm), '');
      result.push(metricId);
    }
  }

  return result;
}

export type WidgetMetricDisplayNameMap = Record<string, string>;

export const getWidgetMetricDisplayNameMap = (
  widget: BIWidget
): WidgetMetricDisplayNameMap =>
  Object.fromEntries(
    Object.entries(widget.advanced_configurations?.display?.metrics || {})
      .filter(([_, value]) => !!value.display_name_override)
      .map(([key, value]) => [key, value.display_name_override!])
  );

export const getChangeIntervalForMetric = (
  widget: BIWidget,
  metric: BIMetricCreated
): string | undefined => {
  const showDeltas =
    widget?.advanced_configurations?.display?.metrics?.[metric._id]
      ?.show_deltas;

  if (!showDeltas) {
    return;
  }

  const dashboardFilters = widget.dashboard_filters?.find(
    (elem) => elem.column.name === 'shared.__changes_since'
  );

  const changeInterval = dashboardFilters?.value as string[];

  return changeInterval?.[0];
};

export const everyMetricsHaveDateField = (widget: BIWidget): boolean => {
  return widget.metric_list.every((metric: BIMetricUnion) =>
    isBIMetricSimple(metric)
      ? !!metric.date_field
      : isBIMetricFormula(metric)
      ? true
      : false
  );
};

/**
 * Removes empty properties from an object.
 * @param {Object} obj - The input object to clean.
 * @returns {Object} A new object with empty properties removed.
 *
 * This function does the following:
 * 1. Uses Object.entries() to convert the input object into an array of [key, value] pairs.
 * 2. Filters this array to keep only non-empty properties:
 *    - Removes null or undefined values
 *    - Removes empty strings (after trimming)
 * 3. Uses Object.fromEntries() to convert the filtered array back into an object.
 *
 * Empty properties are defined as:
 * - null or undefined values
 * - Empty strings or strings containing only whitespace
 */

export const removeEmptyProps = (obj: { [key: string]: string }) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      if (value === null || value === undefined) return false;
      if (typeof value === 'string' && value.trim() === '') return false;
      return true;
    })
  );
};
