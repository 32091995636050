import * as R from 'ramda';
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { TABLE_ID } from 'common/constants';
import Table from 'components/UI/TableConfig/Table';
import {
  ColumnTypesCallback,
  columnTypes,
  typeToScheme,
} from 'components/UI/TableConfig/column-helper';
import { isColumnConfigClickable } from 'components/UI/TableConfig/types';
import {
  IRow,
  onChangeCallback,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import {
  CUSTOM_OBJECT_TABLE_MODAL_SCHEME,
  useRevBiDrilldownModalWithScheme,
} from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import { getCSVDownloadUrl } from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { useManageRowStatuses } from 'components/hooks/useManageRowStatuses';
import { usePageSize } from 'components/hooks/usePageSize';
import { css } from 'emotion';
import { openModal } from 'navigation/utils';
import {
  getDraftTableRows,
  getSalesforceUrl,
  getShowOrHideColumns,
  isMulticurrencyEnabled,
} from 'selectors';
import { updateDraftTableData } from 'actions/cacheActions';
import { getModalId } from 'components/modals/helper';

import { DeltasTag } from 'components/dashboard/ForecastRollUps/styles';
import {
  configureDeltaTooltip,
  mergeDeltaAndValue,
  parseCsvColumns,
  parseDrilldownItems,
  useManageDrilldownData,
} from 'components/hooks/useManageDrilldownData';
import { parseCustomObjectColumns } from 'components/modals/CustomObjectModal/CustomObjectModal.helper';

const modalHeight = css`
  height: 80vh;
`;

export const CustomObjectTableModal: React.FC = () => {
  const dispatch = useDispatch();

  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);
  const [pageSize] = usePageSize('RevbiCustomObject', 15);

  const { getProps, markShouldReFetchOnClose } =
    useRevBiDrilldownModalWithScheme(CUSTOM_OBJECT_TABLE_MODAL_SCHEME);

  const modalProps = getProps();
  const modalId = getModalId(
    TABLE_ID.MODAL_CUSTOM_OBJECT,
    modalProps?.metricName,
    modalProps?.dashboardId,
    modalProps?.widgetId
  );

  const isMultiCurrencyEnabled = useSelector(isMulticurrencyEnabled);
  const draftData: IRow[] = useSelector((state) =>
    getDraftTableRows(modalId, state)
  );

  const [userUpdatedField, setUserUpdatedField] = useState<boolean>();

  const {
    rowStatuses,
    setLoadingRowField,
    setSuccessRowField,
    setErrorRowField,
  } = useManageRowStatuses();

  const params = {
    ...modalProps.drilldownParams,
    limit: pageSize,
  };
  const showOrHideColumns = useSelector(getShowOrHideColumns);

  const {
    columns,
    items,
    deltaItems,
    itemsCount,
    isLoading,
    isSuccess,
    isDeltaLoading,
    isDeltaSuccess,
    updateObjectField,
    totalRow,

    handlePaginationChange,
    pageNumber,
    handleSort,
    sortOrder,

    invalidateData,
  } = useManageDrilldownData({
    tableSettingOrUlr: modalProps.apiUrl,
    metricObject: modalProps.metricObject,
    params,
    changeInterval: modalProps.changeInterval,
    mutationOptions: {
      doNotInvalidateOnSettled: true,
    },
  });

  const handleOpportunitiesTableSpecialClick: ColumnTypesCallback = (props) => {
    if (
      isColumnConfigClickable(props.columnConfig) &&
      props.columnConfig.meta.object_id &&
      props.row?.opportunity
    ) {
      const scheme = typeToScheme[props.columnConfig.meta.object_id];
      const opp: IRow = props.row.opportunity as IRow;

      if (scheme) {
        openModal({
          scheme,
          params: { id: opp[props.columnConfig.meta.object_id] as string },
        });
      } else {
        console.warn('Action not supported');
      }
    }
  };

  const getColumnType = columnTypes(
    {
      currencyFormatter: currencyFormatter(
        companyCurrencyCode,
        0,
        isMultiCurrencyEnabled
      ),
      sequence: [],
    },
    handleOpportunitiesTableSpecialClick,
    '',
    isMultiCurrencyEnabled
  );

  const tableColumns = parseCustomObjectColumns(columns, getColumnType).map(
    (column) => configureDeltaTooltip(column, modalProps.changeInterval)
  );

  const salesforceUrl = useSelector(getSalesforceUrl);

  const rows = useMemo(() => {
    const parsedItems = parseDrilldownItems(items, salesforceUrl);

    if (!isDeltaSuccess) {
      return parsedItems;
    }

    return parsedItems.map((item) =>
      mergeDeltaAndValue(
        item,
        deltaItems.find((deltaItem) => deltaItem.id === item.id),
        tableColumns
      )
    );
  }, [items, deltaItems, tableColumns, salesforceUrl, isDeltaSuccess]);

  const csvColumns = parseCsvColumns(columns);

  const downloadButton = useMemo(
    () =>
      getCSVDownloadUrl(
        modalProps.metricObject,
        modalProps.drilldownParams,
        csvColumns
      ),
    [modalProps.drilldownParams, csvColumns]
  );

  const tableTitle: string = useMemo(() => {
    return `${modalProps?.metricName ?? ''}`;
  }, [modalProps]);

  const handleChange: onChangeCallback = async (column, row, newValue) => {
    const objectId = row.id.toString();
    const fieldToUpdate = column.field;
    const updateOperation = {
      [fieldToUpdate]: newValue,
    };

    setLoadingRowField(objectId, fieldToUpdate);

    try {
      await updateObjectField({
        id: objectId,
        editedFields: updateOperation,
      });

      toast.success('Field updated successfully');
      setSuccessRowField(objectId, fieldToUpdate);
      setUserUpdatedField(true);
      markShouldReFetchOnClose();
    } catch (e) {
      const errorMessage =
        isAxiosError(e) && e.response?.data?.error?.message?.[0];
      toast.error(errorMessage || 'Failed to update field');
      setErrorRowField(objectId, fieldToUpdate);
    }
  };

  const onRefreshClick = () => {
    setUserUpdatedField(false);
    invalidateData();
  };

  const handleDraftChange: onChangeCallback = (column, row, newValue) => {
    const draftItem = draftData?.find((item) => item.id === row.id) || {
      id: row.id,
    };
    const draftListWithoutItem =
      draftData?.filter((item) => item.id !== row.id) || [];

    dispatch(
      updateDraftTableData(modalId, [
        ...draftListWithoutItem,
        R.assocPath(column.field.split('.'), newValue, draftItem),
      ])
    );
  };

  if (!modalProps) {
    return null;
  }

  return (
    <div className={modalHeight}>
      <Table
        renderTitleExtra={() =>
          isSuccess &&
          isDeltaLoading && <DeltasTag>Fetching deltas ... </DeltasTag>
        }
        tableId={`${TABLE_ID.MODAL_CUSTOM_OBJECT}-${modalProps.metricObject}`}
        pinnableColumns
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
        hasRefreshButton={userUpdatedField}
        onRefresh={onRefreshClick}
        totalRow={totalRow}
        statuses={rowStatuses}
        totalAmount={0}
        showOrHideColumns={showOrHideColumns}
        loading={isLoading}
        title={tableTitle}
        totalCount={itemsCount}
        currentPage={pageNumber}
        rowsPerPage={pageSize}
        downloadButton={downloadButton}
        data={rows}
        columns={tableColumns}
        showTotalAmount={false}
        onPaginationChange={handlePaginationChange}
        onChange={handleChange}
        sortOrder={sortOrder}
        onSort={handleSort}
        onDraftChange={handleDraftChange}
        draftData={draftData}
        styleFirstColumn
        firstColumnSize={400}
        showColumnsVisibilityToggle
      />
    </div>
  );
};
