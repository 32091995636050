import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { SubNavPanel } from 'components/dashboard/Metrics/DashboardsSideList/SubNavPanel/SubNavPanel';
import { UNTITLED_DASHBOARD } from 'components/dashboard/Metrics/constants';
import { QueryStatus } from 'utils/network';

type DashboardListItem = {
  name: string;
  id?: string;
};

type SimpleBIDashboardList = {
  name: string;
  id?: string;
}[];

const SubNavContainer = styled.div({
  paddingTop: '24px',
  marginBottom: '0px',
  marginRight: '-15px',
});

const SubNavTitle = styled.div({
  color: 'var(--bu-gray-600)',
  fontSize: '12px',
  paddingLeft: '24px',
  height: '40px',
  alignItems: 'center',
  display: 'flex',
  textTransform: 'uppercase',
});

const SubNavListItem = styled.div((prop: { active?: boolean }) => ({
  height: '40px',
  lineHeight: '40px',
  padding: '0 24px',
  width: '210px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ...(prop.active
    ? {
        backgroundColor: 'var(--bu-primary-200)',
        color: 'var(--bu-primary-500)',
      }
    : {
        cursor: 'pointer',
        color: 'var(--bu-gray-700)',
        '&:hover': {
          backgroundColor: 'var(--bu-primary-200)',
        },
      }),
}));

const Separator = styled.div({
  width: '100%',
  height: '1px',
  backgroundColor: 'var(--bu-gray-500)',
  overflow: 'hidden',
  margin: '8px 0',
});

const MetricsWidgetsButton = styled.div((prop: { active?: boolean }) => ({
  padding: '12px 24px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '> .bu-icon': {
    marginRight: '8px',
    fontSize: '20px',
  },
  ...(prop.active
    ? {
        backgroundColor: 'var(--bu-primary-200)',
        color: 'var(--bu-clickable-cell)',
      }
    : {
        cursor: 'pointer',
        color: 'var(--bu-clickable-cell)',
        '&:hover': {
          color: 'var(--bu-clickable-cell__hover)',
          backgroundColor: 'var(--bu-primary-200)',
        },
      }),
}));

const ButtonContainer = styled.div({
  width: '195px',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '10px',
});

const FixedContainer = styled.div({
  position: 'sticky',
  bottom: '0px',
  backgroundColor: 'var(--bu-white)',
  width: '210px',
});

const LoaderContainer = styled.div({
  position: 'relative',
  height: '100%',
});

const TitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
});

interface Props {
  readonly loadDashboardListStatus: QueryStatus;
  readonly selectedDashboardId?: string;
  readonly dashboardList: SimpleBIDashboardList;
  readonly onAddDashboard: () => void;
}

export const DashboardsSideList: React.FC<Props> = ({
  loadDashboardListStatus,
  selectedDashboardId,
  dashboardList,
  onAddDashboard,
}) => {
  const history = useHistory();

  return (
    <SubNavPanel>
      <SubNavContainer aria-label="Dashboard List">
        <TitleContainer aria-label="Dashboards">
          <SubNavTitle>Dashboards</SubNavTitle>
        </TitleContainer>

        <ButtonContainer>
          <BuButton secondary onClick={onAddDashboard}>
            Create dashboard
          </BuButton>
        </ButtonContainer>

        {loadDashboardListStatus === 'loading' && (
          <LoaderContainer>
            <Loader active />
          </LoaderContainer>
        )}

        {loadDashboardListStatus !== 'loading' &&
          dashboardList.map((dashboard: DashboardListItem, idx: number) => (
            <SubNavListItem
              key={dashboard.id ?? idx}
              aria-label={dashboard.name}
              aria-selected={dashboard.id === selectedDashboardId}
              active={dashboard.id === selectedDashboardId}
              onClick={() => {
                history.replace({
                  pathname: `/revbi/dashboard/${dashboard.id}`,
                });
              }}
            >
              {dashboard.name || UNTITLED_DASHBOARD}
            </SubNavListItem>
          ))}

        <FixedContainer>
          <Separator />
          <MetricsWidgetsButton
            active={history.location.pathname === '/revbi/dashboards/list'}
            onClick={() => {
              history.replace({
                pathname: '/revbi/dashboards/list',
              });
            }}
          >
            <BuIcon
              name={BoostUpIcons.NavDashboard}
              color={
                history.location.pathname === '/revbi/dashboards/list'
                  ? 'var(--bu-primary-500)'
                  : 'var(--bu-primary-500)'
              }
            />
            Dashboards
          </MetricsWidgetsButton>
          <MetricsWidgetsButton
            active={history.location.pathname === '/revbi/widgets/list'}
            onClick={() => {
              history.replace({
                pathname: '/revbi/widgets/list',
              });
            }}
          >
            <BuIcon
              name={BoostUpIcons.CombinedGraphs}
              color={
                history.location.pathname === '/revbi/widgets/list'
                  ? 'var(--bu-primary-500)'
                  : 'var(--bu-primary-500)'
              }
            />
            Widgets
          </MetricsWidgetsButton>
          <MetricsWidgetsButton
            active={history.location.pathname === '/revbi/metrics/list'}
            onClick={() => {
              history.replace({
                pathname: '/revbi/metrics/list',
              });
            }}
          >
            <BuIcon
              name={BoostUpIcons.NavMetric}
              color={
                history.location.pathname === '/revbi/metrics/list'
                  ? 'var(--bu-primary-500)'
                  : 'var(--bu-primary-500)'
              }
            />
            Metrics
          </MetricsWidgetsButton>
        </FixedContainer>
      </SubNavContainer>
    </SubNavPanel>
  );
};
