import * as R from 'ramda';
import Table from 'components/UI/TableConfig/Table';
import {
  IRow,
  onChangeCallback,
} from 'components/UI/common/TypedTable/TypedTable';
import { useManageRowStatuses } from 'components/hooks/useManageRowStatuses';
import { css } from 'emotion';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDraftTableRows,
  getSalesforceUrl,
  getShowOrHideColumns,
} from 'selectors';
import { updateDealMutation } from './RevBiDealsModal.helper';

import { isAxiosError } from 'axios';
import { TABLE_ID } from 'common/constants';

import { useRevBiDrilldownModalWithScheme } from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import { usePageSize } from 'components/hooks/usePageSize';
import { useManageTableConfigurationColumns } from 'components/hooks/useSettingsForTable';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { updateDraftTableData } from 'actions/cacheActions';
import { getModalId } from 'components/modals/helper';

import {
  configureDeltaTooltip,
  mergeDeltaAndValue,
  parseCsvColumns,
  parseDrilldownItems,
  useManageDrilldownData,
} from 'components/hooks/useManageDrilldownData';
import { DeltasTag } from 'components/dashboard/ForecastRollUps/styles';
import { getCSVDownloadUrl } from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
const DEALS_TABLE_SETTINGS = {
  collectionName: 'opportunity',
  tableName: 'RevbiOpportunityTable',
};

const modalHeight = css`
  height: 80vh;
`;

const RevBiDealsModal = () => {
  const dispatch = useDispatch();
  const { getProps, markShouldReFetchOnClose } =
    useRevBiDrilldownModalWithScheme('/revbi-deals-modal');

  const { title, dashboardId, widgetId, drilldownParams, changeInterval } =
    getProps();

  const [dealsPageSize] = usePageSize('RevbiOpportunityTable');

  const modalId = getModalId(
    TABLE_ID.REVBI_DEALS_MODAL,
    title,
    dashboardId,
    widgetId
  );

  const [userUpdatedField, setUserUpdatedField] = useState<boolean>();
  const showOrHideColumns = useSelector(getShowOrHideColumns);
  const draftData: IRow[] = useSelector((state) =>
    getDraftTableRows(modalId, state)
  );

  const {
    rowStatuses,
    setLoadingRowField,
    setSuccessRowField,
    setErrorRowField,
  } = useManageRowStatuses();

  const { parseColumns, sortColumns } = useManageTableConfigurationColumns();

  const params = {
    ...drilldownParams,
    limit: dealsPageSize,
  };

  const {
    columns,
    items,
    isLoading,
    deltaItems,
    isDeltaLoading,
    totalRow,
    itemsCount,
    isSuccess,
    isDeltaSuccess,
    updateObjectField,

    handlePaginationChange,
    pageNumber,
    handleSort,
    sortOrder,
    invalidateData,
  } = useManageDrilldownData({
    metricObject: 'opportunity',
    params: params,
    changeInterval,
    tableSettingOrUlr: DEALS_TABLE_SETTINGS,
    mutationOptions: {
      doNotInvalidateOnSettled: true,
      customMutation: updateDealMutation,
    },
  });

  const tableColumns =
    columns &&
    parseColumns({
      tableConfigurationColumns: columns,
      hiddenColumns: [],
      oppSplitViewToggleEnabled: false,
      onSpecialClick: () => {},
    })
      .map((column) => configureDeltaTooltip(column, changeInterval))
      .map((c) => ({
        ...c,
        field: c.field.replace(
          'additional_fields_delta.',
          'crm_metadata.additional_fields_.'
        ),
      }));

  const sortedColumns = columns && sortColumns(tableColumns);

  const salesforceUrl = useSelector(getSalesforceUrl);

  const rows = useMemo(() => {
    const parsedItems = parseDrilldownItems(items, salesforceUrl);

    if (!isDeltaSuccess) {
      return parsedItems;
    }

    return parsedItems.map((item) =>
      mergeDeltaAndValue(
        item,
        deltaItems.find((deltaItem) => deltaItem.id === item.id),
        tableColumns
      )
    );
  }, [items, deltaItems, tableColumns, salesforceUrl, isDeltaSuccess]);

  const handleChange: onChangeCallback = async (column, row, newValue) => {
    const dealId = row.id.toString();
    const fieldToUpdate = column.field;
    const updateOperation = {
      [fieldToUpdate]: newValue,
    };

    setLoadingRowField(dealId, fieldToUpdate);

    try {
      await updateObjectField({ id: dealId, editedFields: updateOperation });

      toast.success('Deal updated successfully');
      setUserUpdatedField(true);
      setSuccessRowField(dealId, fieldToUpdate);
      markShouldReFetchOnClose();
    } catch (e) {
      const errorMessage =
        isAxiosError(e) && e.response?.data?.error?.message?.[0];
      toast.error(errorMessage || 'Failed to update deal');
      setErrorRowField(dealId, fieldToUpdate);
    }
  };

  const csvColumns = parseCsvColumns(columns);

  const downloadButton = useMemo(
    () => getCSVDownloadUrl('opportunity', drilldownParams, csvColumns),
    [drilldownParams, csvColumns]
  );

  const onRefreshClick = () => {
    setUserUpdatedField(false);
    invalidateData();
  };

  const handleDraftChange: onChangeCallback = (column, row, newValue) => {
    const draftItem = draftData?.find((item) => item.id === row.id) || {
      id: row.id,
    };
    const draftListWithoutItem =
      draftData?.filter((item) => item.id !== row.id) || [];

    dispatch(
      updateDraftTableData(modalId, [
        ...draftListWithoutItem,
        R.assocPath(column.field.split('.'), newValue, draftItem),
      ])
    );
  };

  // If you modify the layout remember to leave the data-testing attribute for automation
  return (
    <div className={modalHeight} data-testing="deals-modal">
      <Table
        tableId={TABLE_ID.REVBI_DEALS_MODAL}
        pinnableColumns
        title={title}
        renderTitleExtra={() =>
          isSuccess &&
          isDeltaLoading && <DeltasTag>Fetching deltas ... </DeltasTag>
        }
        totalRow={totalRow}
        totalCount={itemsCount}
        columns={sortedColumns || []}
        data={rows}
        loading={isLoading}
        showOrHideColumns={showOrHideColumns}
        onRefresh={onRefreshClick}
        hasRefreshButton={userUpdatedField}
        onChange={handleChange}
        statuses={rowStatuses}
        rowsPerPage={dealsPageSize}
        currentPage={pageNumber}
        onPaginationChange={handlePaginationChange}
        onSort={handleSort}
        sortOrder={sortOrder}
        downloadButton={downloadButton}
        showTotalAmount={false}
        dataType="deals"
        showColumnsVisibilityToggle
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
        onDraftChange={handleDraftChange}
        draftData={draftData}
        styleFirstColumn
        firstColumnSize={400}
      />
    </div>
  );
};

export default RevBiDealsModal;
