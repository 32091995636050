import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CustomObjectDrilldownDeltaItem,
  CustomObjectDrilldownItem,
  getChangesSinceFilter,
  GetDrilldownDataForMetricObjectReturn,
  GetDrilldownDataForTablePayload,
  getDrilldownDeltaDataForMetricObject,
  getDrilldownTotals,
  getIdsFilter,
  updateObjectField,
} from 'api/RevBiDrilldown';
import {
  getDrilldownDataForMetricObject,
  GetDrilldownDataParams,
} from 'api/RevBiDrilldown';
import { GetSettingsForTableParams } from 'api/TableSettings';
import { mapOpportunitiesColumnsToTotalColumns } from 'components/dashboard/Forecast/NewForecast.helper';
import { getRawValue } from 'components/dashboard/ForecastRollUps/helpers/rowValueExtractors';
import { RevbiModalDeltaTooltip } from 'components/dashboard/Metrics/common/RevbiModalDeltaTooltip/RevbiModalDeltaTooltip';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { useGetSettingsForTable } from 'components/hooks/useSettingsForTable';
import { CsvColumns } from 'components/modals/types';
import { getTotalsRow } from 'components/UI/common/TypedTable/helper';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import {
  IColumn,
  IRow,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { get, set } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsTotalsRowEnabled } from 'selectors';

const EXTRA_ID_SPLITS_OBJECT = {
  keyProperty: 'opportunity.id',
  fullTablePath: 'opportunity.id',
};

const DRILLDOWN_KEY = 'getDrilldownDataForMetricObject';

export type CustomMutation = (
  metricObject: string,
  id: string,
  editedFields: GetDrilldownDataForTablePayload
) => Promise<void>;

interface UseUpdateFieldMutationOptions {
  doNotInvalidateOnSettled?: boolean;
  customMutation?: CustomMutation;
}

interface UseManageDrilldownDataParams {
  tableSettingOrUlr: GetSettingsForTableParams;
  metricObject: string;
  params: GetDrilldownDataParams;
  changeInterval?: string;
  mutationOptions?: UseUpdateFieldMutationOptions;
}

// 5 minutes
const STALE_TIME = 5 * 60 * 1000;

// 5 minutes
const CACHE_TIME = 5 * 60 * 1000;

export const useManageDrilldownData = ({
  tableSettingOrUlr,
  metricObject,
  params,
  changeInterval,
  mutationOptions,
}: UseManageDrilldownDataParams) => {
  const isTotalsRowEnabled = useSelector(getIsTotalsRowEnabled);
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState(1);
  const [userSortOrder, setUserSortOrder] = useState<string>();

  const { primaryKeysPerTable } = useContext(RevBISettingsContext);
  const primaryKeyInfo = primaryKeysPerTable[metricObject];

  const handlePaginationChange = (page: number) => {
    setPageNumber(page);
  };

  const handleSort = (sort?: string) => {
    setUserSortOrder(sort);
  };

  /**
   * Get table settings - Columns and order
   */

  const { data: tableConfig, isLoading: isTableSettingsLoading } =
    useGetSettingsForTable(tableSettingOrUlr);

  /**
   * Get drilldown data
   */

  const drilldownColumns = tableConfig?.columns || [];

  const defaultSortOrder = tableConfig?.order.object_field
    ? `${tableConfig?.order.direction === -1 ? '-' : ''}${
        tableConfig?.order.object_field
      }`
    : '';

  const sortOrder = userSortOrder || defaultSortOrder;

  const drilldownDataParams = {
    ...params,
    columns: drilldownColumns.map((c) => c.object_field),
    order_by_expression: params.order_by_expression
      ? params.order_by_expression
      : sortOrder,
    offset: pageNumber - 1,
  };

  const {
    drilldownData,
    isDrilldownDataLoading,
    isDrilldownDataSuccess,
    totalCount,
  } = useGetDrilldownData(
    metricObject,
    drilldownDataParams,
    !isTableSettingsLoading
  );

  /**
   * Get totals
   */

  const totalParams = {
    ...params,
    columns: drilldownColumns
      .map((c) => c.object_field)
      .filter(Boolean) as string[],
  };

  const { data: drilldownTotals, isLoading: areTotalsLoading } = useQuery({
    queryKey: [DRILLDOWN_KEY, 'totals', params],
    queryFn: () => getDrilldownTotals(metricObject, totalParams),
    enabled: !isTableSettingsLoading && isTotalsRowEnabled,
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
  });

  const totalRow =
    !!tableConfig && isTotalsRowEnabled && tableConfig.columns
      ? getTotalsRow(
          drilldownTotals,
          areTotalsLoading,
          mapOpportunitiesColumnsToTotalColumns(tableConfig.columns)
        )
      : undefined;

  /**
   * Get Deltas
   */

  const itemsIds = drilldownData?.items.map((item) => item.id) ?? [];

  // For some reason the BE takes a long time to return delta for splits
  // So to improve performance, we add an extra ids filter for splits
  // and it is hardcoded, probably we will remove this once BE returns delta for splits faster
  const isSplitMetric = metricObject === 'opportunity_split';
  const splitsIds = isSplitMetric
    ? drilldownData?.items.map((item) => item.opportunity._id as string)
    : [];
  const splitIdsFilter =
    isSplitMetric && splitsIds?.length
      ? getIdsFilter(splitsIds, EXTRA_ID_SPLITS_OBJECT)
      : undefined;

  const deltaParams =
    changeInterval && itemsIds.length
      ? {
          ...drilldownDataParams,
          change_interval: changeInterval,

          // Clean filters as ids are already sent
          // and adding extra filters can cause issues

          template_filters: [],
          drill_down_filters: [],
          filters: [],

          // same as above, no need to send whatever dashboard filters had
          dashboard_filters: [
            getIdsFilter(itemsIds, primaryKeyInfo),
            getChangesSinceFilter(changeInterval),

            // Only for splits
            ...(splitIdsFilter ? [splitIdsFilter] : []),
          ],
        }
      : undefined;

  const {
    data: deltaData,
    isFetching: isDeltaLoading,
    isSuccess: isDeltaSuccess,
  } = useQuery({
    queryKey: [DRILLDOWN_KEY, 'delta', deltaParams],
    queryFn: () =>
      // If deltaParams is undefined, the query will not be executed
      getDrilldownDeltaDataForMetricObject(
        metricObject,
        deltaParams!,
        primaryKeyInfo
      ),
    enabled: isDrilldownDataSuccess && !!drilldownData && !!deltaParams,

    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
  });

  /**
   * Update field mutation
   */

  const updateFieldMutation = useUpdateFieldMutation(
    metricObject,
    drilldownDataParams,
    mutationOptions
  );

  /**
   * Ivalidation
   */

  const invalidateData = () => {
    queryClient.invalidateQueries(['getDrilldownDataForMetricObject']);
  };

  // On unmount invalidate the query to avoid stale data
  // if the user opens the modal again
  useEffect(() => {
    return () => {
      invalidateData();
    };
  }, []);

  const isLoading = isTableSettingsLoading || isDrilldownDataLoading;

  return {
    columns: drilldownColumns,
    items: drilldownData?.items || [],
    isLoading,
    deltaItems: deltaData?.items || [],
    isDeltaLoading,
    isDeltaSuccess,
    totalRow,
    itemsCount: totalCount,
    isSuccess: isDrilldownDataSuccess,
    updateObjectField: updateFieldMutation.mutateAsync,

    handlePaginationChange,
    pageNumber,
    handleSort,
    sortOrder,
    invalidateData,
  };
};

export const useGetDrilldownData = (
  metricObject: string,
  params: GetDrilldownDataParams,
  canFetch: boolean
) => {
  const [totalCount, setTotalCount] = useState(0);
  const { primaryKeysPerTable } = useContext(RevBISettingsContext);
  const primaryKeyInfo = primaryKeysPerTable[metricObject];

  const getDrilldownDataQueryKey = [DRILLDOWN_KEY, metricObject, params];
  const {
    data: drilldownData,
    isLoading: isDrilldownDataLoading,
    isSuccess: isDrilldownDataSuccess,
  } = useQuery({
    queryKey: getDrilldownDataQueryKey,
    queryFn: () =>
      getDrilldownDataForMetricObject(metricObject, params, primaryKeyInfo),
    enabled: canFetch,
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: (data) => {
      if (!totalCount) {
        setTotalCount(data.count ?? 0);
      }
    },
  });

  return {
    drilldownData,
    isDrilldownDataLoading,
    isDrilldownDataSuccess,
    totalCount,
  };
};

const MUTATION_KEY = ['updateObjectField'];

const useUpdateFieldMutation = (
  metricObject: string,
  params: GetDrilldownDataParams,
  options?: UseUpdateFieldMutationOptions
) => {
  const { doNotInvalidateOnSettled = false, customMutation } = options || {};

  const { primaryKeysPerTable } = useContext(RevBISettingsContext);
  const primaryKeyInfo = primaryKeysPerTable[metricObject];
  const primaryKey = primaryKeyInfo?.keyProperty || 'id';

  const keyToUpdate = [DRILLDOWN_KEY, metricObject, params];
  const queryClient = useQueryClient();
  const updateFieldMutation = useMutation({
    mutationFn: ({
      id,
      editedFields,
    }: {
      id: string;
      editedFields: GetDrilldownDataForTablePayload;
    }) => {
      if (customMutation) {
        return customMutation(metricObject, id, editedFields);
      } else {
        return updateObjectField(metricObject, id, editedFields);
      }
    },
    onMutate: async (update) => {
      await queryClient.cancelQueries(keyToUpdate);

      const currentData =
        queryClient.getQueryData<GetDrilldownDataForMetricObjectReturn>(
          keyToUpdate
        );

      if (!currentData) {
        return;
      }

      const itemBeingUpdated = currentData?.items.find(
        (item) => item[primaryKey] === update.id
      );

      if (!itemBeingUpdated) {
        return;
      }

      const originalFieldValues: Record<string, any> = Object.fromEntries(
        Object.keys(update.editedFields).map((key) => [
          key,
          get(itemBeingUpdated, key),
        ])
      );

      const optimisticUpdate = {
        ...itemBeingUpdated,
      };

      Object.entries(update.editedFields).forEach(([key, value]) => {
        // Remove crm_metadata and additional_fields_ prefixes.
        const cleanKey = key
          .replace('crm_metadata.', '')
          .replace('additional_fields_.', '');

        if (
          'currency_metadata' in optimisticUpdate &&
          Object.keys(optimisticUpdate.currency_metadata).includes(cleanKey)
        ) {
          // The original value is stored in the currency_metadata object
          const currencyMetadataKey = `${cleanKey}.original_value`;
          set(optimisticUpdate.currency_metadata, currencyMetadataKey, value);
        } else {
          set(optimisticUpdate, key, value);
        }
      });

      const newData = {
        ...currentData,
        items: currentData.items.map((item) =>
          item[primaryKey] === optimisticUpdate[primaryKey]
            ? optimisticUpdate
            : item
        ),
      };

      queryClient.setQueryData(keyToUpdate, newData);

      return {
        itemIdTryingToUpdate: optimisticUpdate[primaryKey],
        originalFieldValues,
      };
    },
    onSettled: () => {
      const noMutationOfSameKeyInProgress =
        queryClient.isMutating({ mutationKey: MUTATION_KEY }) <= 1;

      const canInvalidate = !doNotInvalidateOnSettled;

      if (noMutationOfSameKeyInProgress && canInvalidate) {
        queryClient.invalidateQueries(keyToUpdate);
      }
    },
    onError: (_err, _variables, context) => {
      if (
        !context ||
        !context.itemIdTryingToUpdate ||
        !context.originalFieldValues
      ) {
        return;
      }

      const dataWithOptimisticUpdate =
        queryClient.getQueryData<GetDrilldownDataForMetricObjectReturn>(
          keyToUpdate
        );

      if (!dataWithOptimisticUpdate) {
        return;
      }

      const { itemIdTryingToUpdate, originalFieldValues } = context;

      const optimisticItem = dataWithOptimisticUpdate.items.find(
        (item) => item[primaryKey] === itemIdTryingToUpdate
      );

      if (!optimisticItem) {
        return;
      }

      const revertedItem = {
        ...optimisticItem,
      };

      Object.entries(originalFieldValues).forEach(([key, value]) => {
        set(revertedItem, key, value);
      });

      const dataWithItemReverted: GetDrilldownDataForMetricObjectReturn = {
        ...dataWithOptimisticUpdate,
        items: dataWithOptimisticUpdate.items.map((item) =>
          item[primaryKey] === revertedItem[primaryKey] ? revertedItem : item
        ),
      };

      queryClient.setQueryData(keyToUpdate, dataWithItemReverted);
    },
  });

  return updateFieldMutation;
};

export const parseDrilldownItems = (
  items: CustomObjectDrilldownItem[],
  salesforceUrl?: string
): IRow[] =>
  items.map((item) => {
    const hasCrmAndNextStepIsNull =
      item.crm_metadata && item.crm_metadata.next_step === null;

    return {
      ...item,
      // IMO this should be sent directly by backend
      // Instead of FE building the url through salesforceUrl
      // exposed on settings. But they decided to do it this way
      salesforceLink:
        salesforceUrl && getSalesforceLink(salesforceUrl, item.id),
      crm_metadata: hasCrmAndNextStepIsNull
        ? { ...item.crm_metadata, next_step: '' }
        : item.crm_metadata,
    };
  });

export const parseCsvColumns = (
  columns: TableConfigurationColumn[]
): CsvColumns[] =>
  columns.map((c) => ({
    display_name: c.display_name,
    object_field:
      c.object_field === 'crm_metadata.opportunity_name'
        ? 'opportunity_name'
        : c.object_field,
  }));

const getSalesforceLink = (salesforceUrl: string, id: string) =>
  `${salesforceUrl}/lightning/r/object/${id}/view`;

interface FieldWithDeltaCell {
  value: string;
  prev_value: string;
  updatedBy?: string;
  updatedByEmail?: string;
  createdAt?: string;
}

const isFieldWithDeltaCell = (cell: any): cell is FieldWithDeltaCell => {
  return (
    cell && typeof cell === 'object' && 'value' in cell && 'prev_value' in cell
  );
};

const deltaValueDateFormatter = (date: ValueProp) =>
  moment(String(date)).format("MMMM D 'YY");

export const configureDeltaTooltip = (
  column: IColumn,
  changesSince: string | undefined
): IColumn => {
  if (column.delta && changesSince) {
    return {
      ...column,
      config: {
        ...column.config,
        deltaTooltip: (column: IColumn, row: IRow) => {
          const cell = getRawValue(column.field, row);

          if (!isFieldWithDeltaCell(cell)) {
            return null;
          }

          const formattedDate =
            cell.createdAt && moment(cell.createdAt).format("MMMM D 'YY h:mmA");

          const isDateColumn = column.type === ColumnTypes.DATE;

          const valueFormatter = isDateColumn
            ? deltaValueDateFormatter
            : column.config.formatter;

          const currentValue = getFormattedTooltipValue(
            cell.value,
            valueFormatter
          );

          const previousValue = getFormattedTooltipValue(
            cell.prev_value,
            valueFormatter
          );

          const changedByUser = cell.updatedBy;
          const changedByEmail = cell.updatedByEmail;

          return (
            <RevbiModalDeltaTooltip
              currentValue={currentValue as string}
              previousValue={previousValue as string}
              changedByUser={changedByUser}
              changedByEmail={changedByEmail}
              changedDate={formattedDate}
              changesSince={changesSince}
            />
          );
        },
      },
    };
  }

  return column;
};

const getFormattedTooltipValue = (
  value: string | null,
  valueFormatter: IColumn['config']['formatter']
) => {
  if (value == null) {
    return '-';
  }
  return valueFormatter ? valueFormatter(value) : value;
};

export const mergeDeltaAndValue = (
  item: IRow,
  delta: CustomObjectDrilldownDeltaItem | undefined,
  columns: IColumn[]
) => {
  const mergedItem = { ...item };

  columns.forEach((column) => {
    if (column.delta) {
      const currentValue = get(item, column.field);
      const deltaValue = get(delta, column.field);
      const hasValue = currentValue !== undefined;

      if (hasValue) {
        // If column field is a.b.c.field, then the metadata is stored as updates_metadata.field
        const metadataField = column.field.split('.').pop();
        const updateMetadata = delta?.updates_metadata;
        const updatedBy =
          metadataField && updateMetadata?.[metadataField]?.['user.name'];

        const updatedByEmail =
          metadataField && updateMetadata?.[metadataField]?.['user.email'];

        const createdAt =
          metadataField && updateMetadata?.[metadataField]?.created_at;

        const isCurrentValueObject =
          typeof currentValue === 'object' &&
          currentValue !== null &&
          'value' in currentValue;

        const currentValueToUse = isCurrentValueObject
          ? currentValue.value
          : currentValue;

        const isDeltaValueObject =
          typeof deltaValue === 'object' &&
          deltaValue !== null &&
          'value' in deltaValue;

        const deltaValueToUse = isDeltaValueObject
          ? deltaValue.value
          : deltaValue;

        if (currentValueToUse !== deltaValueToUse) {
          const updatedValue = {
            value: currentValueToUse,
            prev_value: deltaValueToUse ?? null,
            updatedBy,
            updatedByEmail,
            createdAt,
          };

          set(mergedItem, column.field, updatedValue);
        }
      }
    }
  });

  return mergedItem;
};
