import { css } from 'emotion';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'actions';
import { TABLE_ID } from 'common/constants';
import Table from 'components/UI/TableConfig/Table';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { getTableOptions } from 'components/dashboard/Metrics/Widget/FunnelWidget/Visualization/Table/helpers';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  BIWidget,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';

export const tableHeaderCellBorders = css`
  & th {
    span {
      text-align: left;
    }
  }
`;

interface Props {
  widget: Partial<BIWidget>;
  funnelData: BIWidgetDataV2;
}

export const FunnelTable: React.FC<Props> = ({ widget, funnelData }) => {
  const dispatch = useDispatch();

  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);

  const handleTableClick = (_: IColumn, row: IRow): void => {
    const dashboardFilters =
      widget.dashboard_filters
        ?.filter((elem) => elem.operator !== 'all')
        ?.filter((filter) => filter.column.name !== 'shared.__changes_since') ??
      [];

    const drilldownParams = {
      filters: widget.funnel_conditions ?? [],
      template_filters: widget.template_filters ?? [],
      dashboard_filters: dashboardFilters,
      funnel_stage: row['stage'] ?? '',
      funnel_stage_column: widget.funnel_stage_column,
      funnel_stages: widget.funnel_stages,
      funnel_object: widget.funnel_object || 'opportunity',
      time_period: widget.time_period,
    };

    openModal({
      scheme: '/funnel-drilldown',
      persistParams: {
        title: `${widget.name} - ${row['stage'] ?? ''}`,
        drilldownParams: drilldownParams,
        showTotalAmount: false,
      },
      persistor: (params: PersistQueryParams) => {
        dispatch(actions.ui.modal.persist(params));
      },
    });
  };

  const tableOptions = getTableOptions(
    funnelData?.pivot_1?.columns || [],
    funnelData?.pivot_1?.data || [],
    companyCurrencyCode,
    handleTableClick
  );

  return (
    <Table
      tableId={TABLE_ID.REVBI_FUNNEl}
      pinnableColumns
      fullscreen
      hidePaginationEnd
      hidePaginationStart
      hideSearch
      columns={tableOptions.columns}
      data={tableOptions.rows}
      rowsPerPage={10}
      currentPage={0}
      className={tableHeaderCellBorders}
    />
  );
};
