import { css } from 'emotion';

import { fontBody } from 'assets/css/fontStyles';

import { cardStyle, tabContentWrapper, notAvilableContent } from '../../styles';

export { tabContentWrapper, notAvilableContent };

export const actionItemsTabWrapper = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);

  .action-items-card {
    ${cardStyle}
    display: flex;
    gap: var(--bu-control-gap-large);

    .content {
      display: flex;
      flex-direction: column;
      gap: var(--bu-control-gap-medium);

      .content-description {
        margin-right: 35px;

        .done-wrapper {
          width: 24px;
          height: 24px;
          border: 1px solid var(--bu-gray-600);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 24px;
          margin-right: 5px;

          &:disabled {
            cursor: not-allowed;
            background-color: var(--bu-gray-300);

            &.done {
              opacity: 0.5;
            }
          }

          &.done {
            background-color: var(--bu-green-500);
          }

          .done-icon {
            i {
              color: var(--bu-gray-400);
              font-size: 14px !important;
            }

            &.done {
              i {
                color: var(--bu-white);
              }
            }
          }
        }

        i {
          font-size: 18px;
        }
      }

      .content-actions {
        margin-left: 25px;

        &.margin-left-medium {
          margin-left: 35px;
        }

        button {
          font-size: 12px;
          margin: 0;
        }
      }

      .content-description,
      .content-actions {
        display: flex;
        align-items: center;
        gap: var(--bu-control-gap-medium);

        i.follow-up-email-icon {
          color: var(--bu-purple-500);
        }

        i.sales-process-icon {
          color: var(--bu-primary-500);
        }
      }
    }
  }
`;

export const followUpEmailModalWrapper = css`
  padding: 12px 0;

  > div {
    padding: 12px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .recipients,
  .subject {
    border-bottom: 1px solid var(--bu-gray-300);
    display: flex;
    align-items: center;
    min-height: 50px;

    .key {
      color: var(--bu-gray-700);
      margin-right: 8px;
    }

    button {
      i {
        font-size: 14px !important;
      }
    }
  }

  .subject {
    height: 32px;
  }

  .body {
    font-family: var(--bu-font-medium);
  }

  .action-button {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: var(--bu-control-gap-large);

    button {
      margin: 0;
    }
  }
`;

export const salesProcessModalWrapper = css`
  .sales-process-modal-title {
    padding: 16px 0;
    ${fontBody}
  }

  .sales-process-modal-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .right-side,
    .left-side {
      flex: 1;
      /* display: flex;
      justify-content: flex-start; */
    }

    .middle-arrow {
      flex: 0.2;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--bu-color-call-light-gray);
      font-size: 20px;
    }

    .left-side-title,
    .right-side-title {
      text-transform: uppercase;
      font-size: 12px;
      font-family: var(--bu-font-semi-bold);
      font-weight: 600;
    }
  }

  .sales-process-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--bu-control-gap-medium);
    padding: 20px 0 12px;
  }
`;

export const salesProcessModalTitle = css`
  display: flex;
  align-items: center;
`;
