import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Popup, Table } from 'semantic-ui-react';

import { actions } from 'actions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatAmount } from 'common/helpers';
import { formatMoney } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import {
  FunnelBackground,
  FunnelBackgroundWrapper,
  FunnelBar,
  FunnelChartWrapper,
  FunnelLabel,
  FunnelLine,
  FunnelValue,
  NoDataMessage,
  PopupLabel,
  PopupValue,
  popupHeader,
  tableCell,
} from 'components/dashboard/Metrics/Widget/FunnelWidget/styles';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  BIWidget,
  BIWidgetColumnV2,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';

interface Props {
  widget: Partial<BIWidget>;
  data: BIWidgetDataV2;
}

export const FunnelChart: React.FC<Props> = ({ widget, data }) => {
  const dispatch = useDispatch();

  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);

  if (!data.pivot_1?.data || data.pivot_1.data.length === 0) {
    return (
      <NoDataMessage>
        <BuIcon name={BoostUpIcons.DangerCircle} />
        No Data
      </NoDataMessage>
    );
  }

  const funnelField =
    data.pivot_1.columns.filter((column) => column.is_funnel)[0].field_name ??
    'stage';
  const pivotField =
    data.pivot_1.columns.filter((column) => column.is_pivot)[0].field_name ??
    'obj_count';
  const maxFunnelField = Math.max(data.pivot_1.data[0][funnelField], 0.01);

  const columnByField: { [key: string]: BIWidgetColumnV2 } =
    data.pivot_1.columns.reduce(
      (prev, curr) => ({ ...prev, [curr['field_name']]: curr }),
      {}
    );

  const formatValue = (
    value: string | number,
    type: string,
    short: boolean = false
  ) => {
    if (type === 'currency') {
      if (short) {
        return formatAmount(companyCurrencyCode, value as number);
      } else {
        return formatMoney(companyCurrencyCode, value as number);
      }
    }
    if (type === 'percent') {
      return `${value}%`;
    }
    return value;
  };

  const handleChartClick = (value: string) => {
    const dashboardFilters =
      widget.dashboard_filters
        ?.filter((elem) => elem.operator !== 'all')
        ?.filter((filter) => filter.column.name !== 'shared.__changes_since') ??
      [];

    const drilldownParams = {
      filters: widget.funnel_conditions ?? [],
      template_filters: widget.template_filters ?? [],
      dashboard_filters: dashboardFilters,
      funnel_stage: value,
      funnel_stage_column: widget.funnel_stage_column,
      funnel_stages: widget.funnel_stages,
      funnel_object: widget.funnel_object || 'opportunity',
      time_period: widget.time_period,
    };

    openModal({
      scheme: '/funnel-drilldown',
      persistParams: {
        title: `${widget.name} - ${value}`,
        drilldownParams: drilldownParams,
        showTotalAmount: false,
      },
      persistor: (params: PersistQueryParams) => {
        dispatch(actions.ui.modal.persist(params));
      },
    });
  };

  const lastItem = data.pivot_1.data[data.pivot_1.data.length - 1];
  const minSize = Math.min(
    70,
    20 *
      (
        formatValue(
          lastItem[funnelField],
          columnByField[funnelField].type,
          true
        ) as string
      ).length
  );

  return (
    <FunnelChartWrapper data-testing="Funnel_chart">
      {data.pivot_1.data.map((item, index) => (
        <FunnelBar key={index}>
          <FunnelBackgroundWrapper>
            <Popup
              basic
              flowing
              hoverable
              position="top center"
              offset={[-250, -120]}
              trigger={
                <FunnelBackground
                  data-testing="Funnel_chart_bar"
                  className="funnel-background"
                  size={item[funnelField] / maxFunnelField}
                  minSize={minSize}
                  onClick={() => handleChartClick(item['stage'] || '')}
                >
                  <FunnelValue data-testing="Funnel_chart_bar-Deals_number">
                    {formatValue(
                      item[funnelField],
                      columnByField[funnelField].type,
                      true
                    )}
                  </FunnelValue>
                </FunnelBackground>
              }
            >
              <Popup.Header className={popupHeader}>
                {item[pivotField]}
              </Popup.Header>
              <Popup.Content>
                <Table celled data-testing="Funnel_chart_bar-popup-content">
                  <Table.Body>
                    {data.pivot_1?.columns
                      .filter((column) => !column.is_pivot)
                      .map((column) => (
                        <Table.Row key={column.field_name}>
                          <Table.Cell className={tableCell}>
                            <PopupLabel data-testing="Funnel_chart_bar-popup-label">
                              {column.display_name}{' '}
                            </PopupLabel>
                          </Table.Cell>
                          <Table.Cell className={tableCell}>
                            <PopupValue data-testing="Funnel_chart_bar-popup-value">
                              {item[column.field_name] === undefined
                                ? ' - '
                                : formatValue(
                                    item[column.field_name],
                                    column.type
                                  )}
                            </PopupValue>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Popup.Content>
            </Popup>
          </FunnelBackgroundWrapper>
          <FunnelLine
            size={Math.max(item[funnelField] / maxFunnelField, 0.12)}
          />
          <FunnelLabel
            size={Math.max(item[funnelField] / maxFunnelField, 0.12)}
            data-testing="Funnel_chart_bar-Stage_name"
          >
            {item[pivotField]}
          </FunnelLabel>
        </FunnelBar>
      ))}
    </FunnelChartWrapper>
  );
};
