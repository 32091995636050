import React from 'react';
import {
  tooltipLabel,
  tooltipContainer,
  tooltipCaption,
} from './RevbiModalDeltaTooltip.styles';
import { MAP_CHANGES_SINCE_TO_LABEL } from 'components/dashboard/Metrics/constants';

interface DeltaTooltipProps {
  previousValue: string;
  currentValue: string;
  changedByUser?: string;
  changedByEmail?: string;
  changedDate?: string;
  changesSince: string;
}

export const RevbiModalDeltaTooltip: React.FC<DeltaTooltipProps> = ({
  previousValue,
  currentValue,
  changedByUser,
  changedByEmail,
  changedDate,
  changesSince,
}) => {
  return (
    <div className={tooltipContainer}>
      <div>
        <span className={tooltipLabel}>Changed From: </span>
        {previousValue} to {currentValue}
      </div>
      {changedByUser && changedByEmail && (
        <div>
          <span className={tooltipLabel}>Changed By: </span>
          {changedByUser} ({changedByEmail})
        </div>
      )}
      {changedDate && (
        <div>
          <span className={tooltipLabel}>Changed Date: </span>
          {changedDate}
        </div>
      )}
      <div className={tooltipCaption}>
        Delta Based on Changes since {MAP_CHANGES_SINCE_TO_LABEL[changesSince]}
      </div>
    </div>
  );
};
